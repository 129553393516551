import {
    ButtonProps,
    Create,
    Drawer,
    EditButton,
    EmailField,
    Form,
    Input,
    Show,
    ShowButton,
    Space,
    Table,
    Typography,
    useDrawerForm,
} from "@pankod/refine-antd";
import { useGetIdentity, useUpdatePassword } from "@pankod/refine-core";
import { IChangePassForm, IUser } from "interfaces";

import 'dayjs/locale/pt-br';
import { useState } from "react";
import { handleUserRole } from "utils/tableFunctions";
import { CustomList } from "components/template";

const { Title, Text } = Typography;

export const UserProfileList: React.FC = () => {

    const { data: user } = useGetIdentity<IUser>();
    const { mutate: updatePassword } = useUpdatePassword<IChangePassForm>();

    const [visibleShowDrawer, setVisibleShowDrawer] = useState<boolean>(false);
    const [visibleCreateDrawer, setVisibleCreateDrawer] = useState<boolean>(false);

    const handleActions = (_text: any): React.ReactNode => {

        return (
            <Space>
                <ShowButton
                    hideText
                    size="small"
                    onClick={() => {
                        setVisibleShowDrawer(true);
                    }}
                />
            </Space>
        );
    }

    const savePassButtonProps: ButtonProps = {
        children: (<>Salvar</>),
        onClick: async () => {
            const form = createFormProps.form;

            if (form && user) {
                await form.validateFields();

                const formData = form.getFieldsValue() as IChangePassForm;

                await updatePassword({...formData, email: user.email, setVisible: setVisibleCreateDrawer});
                form.resetFields();
            }
        },
    };

    // Create Drawer
    const {
        formProps: createFormProps,
        drawerProps: createDrawerProps,
    } = useDrawerForm<IChangePassForm>({
        action: "create",
        successNotification: {
            message: `Senha alterada com sucesso`, 
            type:'success'
        },
    });

    const PasswordDrawer: React.ReactNode = (
        <Drawer
            {...createDrawerProps}
            visible={visibleCreateDrawer}
            onClose={() => {
                const form = createFormProps.form;
                setVisibleCreateDrawer(false);
                form.resetFields();
            }}
        >
            <Create
                title={"Mudar a senha"}
                headerButtons={<></>}
                saveButtonProps={savePassButtonProps}
                contentProps={{
                    style: {
                        boxShadow: "none",
                    },
                    bodyStyle: {
                        padding: 0,
                    },
                }}
            >
                <Form
                    {...createFormProps}
                    layout="vertical"
                >
                    {ChangePassFormItems}
                </Form>
            </Create>
        </Drawer>
    );

    const ShowDrawer: React.ReactNode = (
        <Drawer
            visible={visibleShowDrawer}
            onClose={() => setVisibleShowDrawer(false)}
            width="500"
        >
            <Show
                title={'Seus dados'}
                headerButtons={<></>}
            >
                <Title level={5}>E-mail</Title>
                <Text>{user?.email}</Text>

                <Title level={5}>Nome</Title>
                <Text>{user?.name}</Text>

                <Title level={5}>Tipo de usuário</Title>
                {
                    user?.role &&
                    handleUserRole(user?.role)
                }

                <Title level={5} style={{ marginTop: '20px' }}>Bank ID</Title>
                <Text>{user?.bankId}</Text>
            </Show>
        </Drawer>
    );

    const headerButtons= () => {
        return (
            <EditButton
                onClick={() => {
                    setVisibleCreateDrawer(true);
                }}
            >
                Mudar Senha
            </EditButton>
        );
    }

    if (user) {
        return (
            <CustomList
            title='Você'
            headerButtons= {headerButtons()}
            >
                <Table
                    dataSource={[{ ...user, key: 1 }]}
                    pagination={{ hideOnSinglePage: true }}
                >
                    <Table.Column
                        dataIndex={"email"}
                        title="Username / e-mail"
                        render={(value: string) => <EmailField value={value} /> ?? '****'}
                    />

                    <Table.Column
                        dataIndex={"name"}
                        title="Nome"
                        render={(value: string) => value ?? '****'}
                    />

                    <Table.Column
                        dataIndex={"role"}
                        title="Tipo de usuário"
                        render={handleUserRole}
                    />

                    <Table.Column
                        dataIndex={"bankId"}
                        title="Bank ID"
                        render={(value: string) => value ?? '****'}
                    />

                    <Table.Column<IUser>
                        dataIndex="actions"
                        title="Ações"
                        render={handleActions}
                    />
                </Table>
                {PasswordDrawer}
                {ShowDrawer}
            </CustomList>
        );
    } else {
        return (<></>)
    }
}


const ChangePassFormItems = (
    <><Form.Item
        name="password"
        label="Senha Atual"
        rules={[{ required: true }]}
        style={{ marginBottom: "12px" }}
    >
        <Input.Password size="large" />
    </Form.Item><Form.Item
        name="newPassword"
        label="Nova Senha"
        rules={[
            { required: true },
            () => ({
                validator(_, value) {
                    const hasSpecialChar = /[@#$%^&+=]/.test(value);
                    const hasUppercase = /[A-Z]/.test(value);
                    const hasLowercase = /[a-z]/.test(value);
                    const hasLength = value.length >= 8;

                    if (hasSpecialChar && hasUppercase && hasLowercase && hasLength) {
                        return Promise.resolve();
                    }

                    return Promise.reject('A senha deve ter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula e um caractere especial.');
                },
            }),
        ]}
        style={{ marginBottom: "12px" }}
    >
            <Input.Password size="large" />
        </Form.Item><Form.Item
            name="confirmPassword"
            label="Confirmar Senha"
            dependencies={['newPassword']}
            rules={[
                { required: true },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        const newPassword = getFieldValue('newPassword');
                        if (newPassword === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject('As senhas não coincidem.');
                    },
                }),
            ]}
            style={{ marginBottom: "12px" }}
        >
            <Input.Password size="large" />
        </Form.Item></>
);
