import React, { useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { useTranslate } from "@pankod/refine-core";
import { FilterDropdownProps } from "@pankod/refine-antd/dist/components/table/components/filterDropdown";
import { Space, Button } from "@pankod/refine-antd";

export const CustomFilterDropdown: React.FC<FilterDropdownProps> = (props) => {
  const {
    setSelectedKeys,
    confirm,
    clearFilters,
    mapValue,
    selectedKeys,
    children,
  } = props;

  const [value, setValue] = useState<any[] | undefined>(selectedKeys);
  const translate = useTranslate();

  const clearFilter = () => {
    if (clearFilters) {
      setValue([]);
      clearFilters();
      confirm?.();
    }
  };

  const onFilter = () => {
    const _mappedValue = mappedValue(value);
    setSelectedKeys(_mappedValue);

    confirm?.();
  };

  const mappedValue = (value: any) => (mapValue ? mapValue(value) : value);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    if (typeof e === "object") {
      if (Array.isArray(e)) {
        const _mappedValue = mappedValue(e);

        setValue(_mappedValue);
        return setSelectedKeys(_mappedValue);
      }

      const { target }: React.ChangeEvent<HTMLInputElement> = e;
      const _mappedValue = mappedValue(target.value);
      setValue(_mappedValue);
      return;
    }

    const _mappedValue = mappedValue(e);

    setValue(_mappedValue);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as any, {
        onChange,
        value: mappedValue(value),
      });
    }
    return child;
  });
  return (
    <div
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <div style={{ marginBottom: 15 }}>{childrenWithProps}</div>
      <Space>
        <Button type="primary" size="small" onClick={() => onFilter()}>
          <FilterOutlined /> {translate("buttons.filter", "Filtrar")}
        </Button>
        <Button danger size="small" onClick={() => clearFilter()}>
          {translate("buttons.clear", "Limpar")}
        </Button>
      </Space>
    </div>
  );
};
