import React from "react";
import { Button } from "antd";
import { Popconfirm } from "@pankod/refine-antd";
import { DeleteOutlined } from "@ant-design/icons";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface CustomDeleteButtonProps {
  body: number[];
  handleDelete: () => {};
  size?: SizeType;
  disabled?: boolean;
  hideText?: boolean;
  style?: React.CSSProperties | undefined;
}

export class CustomDeleteButton extends React.Component<CustomDeleteButtonProps> {
  render() {
    return (
      <>
        <Popconfirm
          title="Você tem certeza?"
          okText="Excluir"
          cancelText="Cancelar"
          onConfirm={this.props.handleDelete}
        >
          <Button
            disabled={this.props.disabled}
            danger
            size={this.props.size}
            style={this.props.style}
            icon={<DeleteOutlined />}
          >
            {this.props.hideText ? "" : "Excluir"}
          </Button>
        </Popconfirm>
      </>
    );
  }
}
