import { BankStateContext } from "context/bank";
import { PaymentStateContext } from "context/payment";
import { useContext } from "react";

export const usePayment = () => {
  return useContext(PaymentStateContext);
};

export const useBank = () => {
  return useContext(BankStateContext);
};
