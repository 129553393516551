import { Button, Modal, Show } from "@pankod/refine-antd";
import { DownloadOutlined } from "@ant-design/icons";
import {List} from "antd";

export const FileErrorsModal = (props: { 
    visible: boolean,
    onCancel: ()=>void,
    messages: string[] 
}) => {

    const {visible, onCancel, messages} = props;

    return (
        <Modal
            visible={visible}
            width={1000}
            onCancel={onCancel}
            footer={(<DownloadButton items={messages}/>)}
        >
            <Show 
                title="Erros do arquivo importado"
                canDelete={false}
                headerButtons={<></>}
            >
                <List
                    size="small"
                    bordered
                    dataSource={messages}
                    renderItem={(item: string) => <List.Item>{item}</List.Item>}
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                />
            </Show>
        </Modal>
    );
};

const DownloadButton = ({ items }: { items: string[] }) => {
  const handleDownload = () => {
    const content = items.join('\n');
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'erros-lote.txt';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Button type="primary" icon={<DownloadOutlined />} onClick={handleDownload}>
        Baixar TXT
    </Button>
  );
};
