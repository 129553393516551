import { useTable, Select } from "@pankod/refine-antd";
import { CrudSorting, HttpError, useGetIdentity } from "@pankod/refine-core";
import { IBatch, IUser } from "interfaces";
import { useState, useEffect, useMemo } from "react";

import "dayjs/locale/pt-br";

import { BatchPageType, UserRole } from "enums";
import { buildErrorNotification } from "utils/errorValidation";
import { HeaderPage } from "components/header";
import { BatchActions } from "components/batch/actions";
import { BatchesTemplatePage } from "components/batch/template";
import { UpdateBatchHeaderButton } from "components/buttons/updateBatchHeader";
import { PaymentReportButton } from "components/buttons/paymentReport";
import { useBank } from "hooks";

export const AllBatchesList: React.FC = () => {
  const { bankId, setBankId, bankOptions } = useBank();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  useState<boolean>(false);

  const { data: user } = useGetIdentity<IUser>();

  useEffect(() => {
    if (user) {
      const userRole = user.role as UserRole;
      setIsAdmin(userRole === UserRole.ADMIN);
    }
  }, [user]);

  useEffect(() => {
    if (bankId) {
      setIsTableLoading(true);
      refetchBatchTable().then(() => setIsTableLoading(false));
    }
  }, [bankId]);

  const initialSorter: CrudSorting = useMemo(
    () => [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    [],
  );

  const {
    tableProps,
    sorter,
    setSorter,
    filters,
    setFilters,
    tableQueryResult: { refetch: refetchBatchTable },
  } = useTable<IBatch, HttpError>({
    resource: "batch",
    initialSorter: initialSorter,

    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os lotes!",
      );
    },
  });

  const handleActions = (_text: any, record: IBatch): React.ReactNode => {
    return (
      <BatchActions
        batch={record}
        isLoading={isTableLoading}
        setIsLoading={setIsTableLoading}
        refetchTable={async () => {
          await refetchBatchTable();
        }}
      />
    );
  };

  const BatchHeaderPage: JSX.Element = (
    <HeaderPage>
      {isAdmin && (
        <Select
          disabled={isTableLoading}
          options={bankOptions}
          defaultValue={bankId}
          onChange={setBankId}
        />
      )}
      <UpdateBatchHeaderButton
        batches={tableProps.dataSource ?? []}
        isTableLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        refetchBatchTable={refetchBatchTable}
      />
      <PaymentReportButton
        isTableLoading={isTableLoading}
        filters={filters}
        sorter={sorter}
      />
    </HeaderPage>
  );

  return (
    <BatchesTemplatePage
      topElement={BatchHeaderPage}
      title="Todos os lotes"
      pageType={BatchPageType.ALL}
      tableProps={tableProps}
      isTableLoading={isTableLoading}
      handleActions={handleActions}
      setFilters={setFilters}
      setSorter={setSorter}
    />
  );
};
