import {
  useTable,
  Space,
  useDrawerForm,
  Button,
  notification,
  Select,
} from "@pankod/refine-antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  CrudFilters,
  CrudSorting,
  HttpError,
  useApiUrl,
  useCheckError,
  useGetIdentity,
  useNavigation,
} from "@pankod/refine-core";
import { IBatch, IPayment, IUser } from "interfaces";
import { useState, useEffect, useMemo } from "react";

import "dayjs/locale/pt-br";

import ImportPaymentDrawer from "components/payment/import";
import { FileErrorsModal } from "components/payment/errors";
import { BatchPageType, UserRole } from "enums";
import { buildErrorNotification } from "utils/errorValidation";
import { HeaderPage } from "components/header";
import { BatchActions } from "components/batch/actions";
import { BatchesTemplatePage } from "components/batch/template";
import { UpdateBatchHeaderButton } from "components/buttons/updateBatchHeader";
import { PaymentReportButton } from "components/buttons/paymentReport";
import { useBank } from "hooks";

export const ImportBatchesList: React.FC = () => {
  const { bankId, setBankId, bankOptions } = useBank();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [fileErrorsMessages, setFileErrorsMessages] = useState<string[]>([]);
  const [visibleFileErrorsMessages, setVisibleFileErrorsMessages] =
    useState<boolean>(false);
  const [canCreatePayment, setCanCreatePayment] = useState<boolean>(false);

  const { data: user } = useGetIdentity<IUser>();

  const { mutate: checkError } = useCheckError();

  const apiUrl = useApiUrl();

  const navigator = useNavigation();

  useEffect(() => {
    if (user) {
      const userRole = user.role as UserRole;

      const canAccess = [
        UserRole.ADMIN,
        UserRole.MANAGER,
        UserRole.OPERATOR,
      ].includes(user.role as UserRole);

      if (!canAccess) {
        navigator.replace("/404");
      }

      setIsAdmin(userRole === UserRole.ADMIN);
      setCanCreatePayment(
        [UserRole.ADMIN, UserRole.OPERATOR, UserRole.MANAGER].includes(
          userRole,
        ),
      );
    }
  }, [user]);

  useEffect(() => {
    if (bankId) {
      setIsTableLoading(true);
      refetchBatchTable().then(() => setIsTableLoading(false));
    }
  }, [bankId]);

  const permanentFilter: CrudFilters = useMemo(
    () => createPermanentFilter(),
    [],
  );

  const initialSorter: CrudSorting = useMemo(
    () => [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    [],
  );

  const {
    tableProps,
    sorter,
    setSorter,
    filters,
    setFilters,
    tableQueryResult: { refetch: refetchBatchTable },
  } = useTable<IBatch, HttpError>({
    resource: "batch",
    permanentFilter: permanentFilter,
    initialSorter: initialSorter,
    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os lotes!",
      );
    },
  });

  const handleActions = (_text: any, record: IBatch): React.ReactNode => {
    return (
      <BatchActions
        batch={record}
        isLoading={isTableLoading}
        setIsLoading={setIsTableLoading}
        refetchTable={async () => {
          await refetchBatchTable();
        }}
      />
    );
  };

  const {
    formProps: importPaymentsFormProps,
    drawerProps: importPaymentsDrawerProps,
    show: showImportPayments,
    close: closeImportPayments,
    form: importPaymentsForm,
  } = useDrawerForm<IPayment[]>({
    action: "create",
  });

  const onSelectPaymentImportFile = async (info: any) => {
    const status = info.file.status;

    switch (status) {
      case "done":
        notification.success({
          message: "Pagamentos importados com Sucesso",
        });
        setIsTableLoading(true);
        closeImportPayments();
        importPaymentsForm.resetFields();
        refetchBatchTable().then(() => setIsTableLoading(false));
        break;
      case "error":
        checkError(info.file.response);
        const errorMessage = info.file.response?.message ?? info.file.response;
        const errorStatus =
          info.file.response?.status ?? info.file.response?.statusCode;

        if (errorStatus === 401) {
          break;
        }

        if (!Array.isArray(errorMessage) || errorMessage.length <= 1) {
          const formmatedMessage = Array.isArray(errorMessage)
            ? errorMessage[0]
            : errorMessage;
          const jsonMessage = JSON.stringify(formmatedMessage, null, 2).replace(
            /"/g,
            "",
          );
          notification.error({
            message: `1 Erro encontrado`,
            description: jsonMessage,
            duration: null,
          });
          break;
        }

        const allErrors = errorMessage.map((msg: any) => {
          const jsonMessage = JSON.stringify(msg, null, 2);
          return jsonMessage.replace(/"/g, "");
        });

        const notificationClick = () => {
          setFileErrorsMessages(allErrors);
          setVisibleFileErrorsMessages(true);
          notification.close("error-modal");
        };

        notification.error({
          message: `${allErrors.length} Erros encontrados`,
          description: `${allErrors[0]} [...]`,
          duration: null,
          key: "error-modal",
          btn: (
            <Space>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  notificationClick();
                }}
              >
                {"Ver Todos >>"}
              </Button>
            </Space>
          ),
        });
        break;

      default:
        break;
    }
  };

  const BatchHeaderPage: JSX.Element = (
    <HeaderPage>
      {isAdmin && (
        <Select
          disabled={isTableLoading}
          options={bankOptions}
          defaultValue={bankId}
          onChange={setBankId}
        />
      )}
      <UpdateBatchHeaderButton
        batches={tableProps.dataSource ?? []}
        isTableLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        refetchBatchTable={refetchBatchTable}
      />
      <PaymentReportButton
        isTableLoading={isTableLoading}
        filters={filters}
        sorter={sorter}
      />
      {canCreatePayment && (
        <Button
          disabled={isTableLoading}
          icon={<UploadOutlined />}
          onClick={() => showImportPayments()}
        >
          Importar
        </Button>
      )}
    </HeaderPage>
  );

  return (
    <div>
      <BatchesTemplatePage
        topElement={BatchHeaderPage}
        title="Importar e acompanhar status de lotes recentes"
        pageType={BatchPageType.IMPORT}
        tableProps={tableProps}
        isTableLoading={isTableLoading}
        handleActions={handleActions}
        showImportPayments={showImportPayments}
        setFilters={setFilters}
        setSorter={setSorter}
      />

      <ImportPaymentDrawer
        createDrawerProps={importPaymentsDrawerProps}
        createFormProps={importPaymentsFormProps}
        apiURL={apiUrl}
        onChange={onSelectPaymentImportFile}
        bankId={bankId || ""}
      />
      <FileErrorsModal
        visible={visibleFileErrorsMessages}
        onCancel={() => {
          setVisibleFileErrorsMessages(false);
        }}
        messages={fileErrorsMessages}
      />
    </div>
  );
};

function createPermanentFilter(): CrudFilters {
  const now = new Date();

  now.setHours(23, 59, 59, 999);

  const threeDaysAgo = new Date(now);
  threeDaysAgo.setDate(now.getDate() - 3);
  threeDaysAgo.setHours(0, 0, 0, 0);

  return [
    {
      field: "createdAt",
      operator: "in",
      value: [threeDaysAgo.toISOString(), now.toISOString()],
    },
  ];
}
