import {
  FormProps,
  Form,
  Row,
  Col,
  Input,
  Icons,
  Select,
  DatePicker,
  Button,
  FilterDropdownProps,
} from "@pankod/refine-antd";

import locale from "antd/es/date-picker/locale/pt_BR";
import { CustomFilterDropdown } from "components/customFilterDropDown";

const UserFilter: React.FC<{
  formProps: FormProps;
  userOptions: { label: string; value: string }[];
}> = ({ formProps, userOptions }) => {
  return (
    <Form {...formProps} layout="vertical">
      <Row gutter={[8, 0]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 7 }}>
          <Form.Item label="Buscar" name="q">
            <Input
              placeholder="E-mail, Nome, Bank ID, etc."
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 10 }} xl={{ span: 5 }}>
          <Form.Item label="Tipo de usuário" name="roles">
            <Select
              allowClear
              options={userOptions}
              placeholder="Selecione o tipo de usuário"
              mode="multiple"
            />
          </Form.Item>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          xl={{ span: 6 }}
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filtrar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const StatusFilter = (props: FilterDropdownProps) => (
  <CustomFilterDropdown {...props}>
    <Select
      style={{ minWidth: 200, maxWidth: 250 }}
      options={[
        {
          label: "Erro",
          value: "error",
        },
        {
          label: "Estornado",
          value: "reversal",
        },
        {
          label: "Indisponivel",
          value: "unavailable",
        },
        {
          label: "Pago",
          value: "paid",
        },
        {
          label: "Pendente",
          value: "pending",
        },
        {
          label: "Processando",
          value: "processing",
        },
      ]}
      placeholder="Status de pagamento"
      mode="multiple"
    />
  </CustomFilterDropdown>
);

const OperationTypeFilter = (props: FilterDropdownProps) => (
  <CustomFilterDropdown {...props}>
    <Select
      style={{ minWidth: 200, maxWidth: 250 }}
      options={[
        {
          label: "BOLETO",
          value: "boleto",
        },
        {
          label: "PIX",
          value: "pix",
        },
        {
          label: "PIX QR CODE",
          value: "pixqrcode",
        },
        {
          label: "TED",
          value: "ted",
        },
      ]}
      placeholder="Tipo de operação"
      mode="multiple"
    />
  </CustomFilterDropdown>
);

const DateFilter = (props: FilterDropdownProps) => (
  <CustomFilterDropdown {...props}>
    <DatePicker.RangePicker locale={locale} format={"DD/MM/YYYY"} />
  </CustomFilterDropdown>
);

export { UserFilter, StatusFilter, OperationTypeFilter, DateFilter };
