import { RcFile } from "@pankod/refine-antd";
// import * as XLSX from 'xlsx';

export function validationFile(file: RcFile): {isValid:boolean, msg:string} {

    const isExcel= file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
    || file.type === 'application/vnd.ms-excel';

    if (!isExcel) {
        return {isValid: false, msg: 'Somente arquivos excel'};
    }

    const isLimit2M = file.size / 1024 / 1024 < 2;

    if (!isLimit2M) {
        return {isValid: false, msg: 'Arquivo não pode ser maior que 2MB!'};
    }

     return {isValid: true, msg: ''};
}

export function excelData(file: RcFile) {


        // var files = e.target.files, f = files[0];

        // var reader = new FileReader();
        // reader.onload = () => {
        //     let readedData = XLSX.read(file.arrayBuffer, {type: 'binary'});
        //     const header = readedData.SheetNames[0];
            
        //     console.log("🚀 ~ file: validationFile.ts ~ line 31 ~ excelData ~ wsname", header);

        //     const ws = readedData.Sheets[wsname];
    
        //     /* Convert array to json*/
        //     const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        //     setFileUploaded(dataParse);
        // };
        // reader.readAsBinaryString(f)
    // }
}


