import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
    position: "relative",
    backgroundColor: "#191c2c"
};
export const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
    backgroundColor: "#191c2c"
};
