import {
  Table,
  useTable,
  Space,
  Button,
  RefreshButton,
  Checkbox,
  Modal,
  Input,
  Select,
  Empty,
  Typography,
} from "@pankod/refine-antd";
import { EyeOutlined, FilterOutlined } from "@ant-design/icons";
import { HttpError, useGetIdentity, useShow } from "@pankod/refine-core";
import { IPayment, IUser } from "interfaces";
import Voucher from "components/voucherTemplate";
import { useState, useEffect, useCallback } from "react";

import "dayjs/locale/pt-br";
import { StatusType, UserRole } from "enums";
import { convertToBRL } from "utils/decimalConverts";
import { CustomDeleteButton } from "components/buttons/customDelete";
import { CustomList, TwoSections } from "components/template";
import {
  handleFiscalId,
  handleStatus,
  handleDate,
  handleAccountBalance,
  getDecodedParamFromPath,
} from "utils/tableFunctions";

import { buildErrorNotification } from "utils/errorValidation";
import { HeaderPage } from "components/header";
import { PaymentShow } from "components/payment/show";
import PaymentSummaryModal from "components/payment/summary";
import { useBank, usePayment } from "hooks";
import {
  DateFilter,
  OperationTypeFilter,
  StatusFilter,
} from "components/filters";
import { CustomFilterDropdown } from "components/customFilterDropDown";
import { PaymentReportButton } from "components/buttons/paymentReport";

export const BatchShow: React.FC = () => {
  const { bankId, setBankId, bankOptions } = useBank();

  const [isAdmin, setIsAdmin] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<IPayment[]>([]);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [isAllPending, setIsAllPending] = useState<boolean>(false);
  const [canDeleteMany, setCanDeleteMany] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [visiblePaymentShowModal, setVisiblePaymentShowModal] =
    useState<boolean>(false);

  const [accountNumbersToUpdate, setAccountNumbersToUpdate] = useState<
    string[]
  >([]);

  const [key, setKey] = useState(0);

  const { data: user } = useGetIdentity<IUser>();
  const [canDeletePayment, setCanDeletePayment] = useState<boolean>(false);

  const batchName = getDecodedParamFromPath(window.location.pathname);

  const { deletePayments, updateBalances } = usePayment();

  const {
    tableProps,
    sorter,
    setSorter,
    filters,
    setFilters,
    tableQueryResult: { refetch: refetchPaymentTable },
  } = useTable<IPayment, HttpError>({
    resource: "payment",
    initialFilter: [
      {
        field: "batchName",
        operator: "eq",
        value: `${batchName}`,
      },
    ],
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os pagamentos!",
      );
    },
  });

  const clearFilters = useCallback(() => {
    setFilters(
      [
        {
          field: "batchName",
          operator: "eq",
          value: `${batchName}`,
        },
      ],
      "replace",
    );
    setSorter([
      {
        field: "id",
        order: "asc",
      },
    ]);
    setKey((prevKey) => prevKey + 1);
  }, [setFilters, setSorter]);

  const ClearButton: JSX.Element = (
    <Button
      disabled={isTableLoading}
      icon={<FilterOutlined />}
      onClick={clearFilters}
    >
      Limpar filtros
    </Button>
  );

  useEffect(() => {
    if (user) {
      const userRole = user.role as UserRole;
      setIsAdmin(userRole === UserRole.ADMIN);
      setCanDeletePayment(
        [
          UserRole.ADMIN,
          UserRole.MANAGER,
          UserRole.APPROVER,
          UserRole.OPERATOR,
        ].includes(userRole),
      );
    }
  }, [user]);

  useEffect(() => {
    if (tableProps.dataSource) {
      const accountNumbers = tableProps.dataSource.map(
        (payment) => payment.payerAccountNumber,
      );
      setAccountNumbersToUpdate(Array.from(new Set(accountNumbers)));
    }
  }, [tableProps.dataSource]);

  const onSelectChange = (
    selectedRowKeys: React.Key[],
    selectedRows: IPayment[],
  ) => {
    const availableRows = selectedRows.filter(
      (payment) =>
        ![
          StatusType.REVERSAL,
          StatusType.PROCESSING,
          StatusType.ERROR,
        ].includes(payment.status as StatusType),
    );

    const firstSelected = selectedRowKeys[0];

    const firstSelectedHasPaid = availableRows.some(
      (payment) =>
        payment.status === StatusType.PAID &&
        payment.id === Number(firstSelected),
    );

    const paidIds = availableRows
      .filter((payment) => payment.status === StatusType.PAID)
      .map((payment) => payment.id);

    const finalPayments = availableRows.filter((value) =>
      firstSelectedHasPaid
        ? paidIds.includes(Number(value.id))
        : !paidIds.includes(Number(value.id)),
    );

    const allSelectedHasPaid = finalPayments.every(
      (payment) => payment.status === StatusType.PAID,
    );

    const allSelectedHasPending = finalPayments.every(
      (payment) => payment.status === StatusType.PENDING,
    );

    const availableToDelete = finalPayments.every((payment) =>
      [StatusType.PENDING, StatusType.UNAVAILABLE].includes(
        payment.status as StatusType,
      ),
    );

    setIsPdf(allSelectedHasPaid);
    setIsAllPending(allSelectedHasPending);
    setCanDeleteMany(availableToDelete && canDeletePayment);
    setSelectedRowData(finalPayments);
    setSelectedRowKeys(finalPayments.map((payment) => payment.id));
  };

  const handleCheckboxRender = (
    _value: boolean,
    record: IPayment,
    _index: number,
    nodeOriginal: any,
  ) => {
    const disabledStatus: string[] = [
      StatusType.REVERSAL,
      StatusType.PROCESSING,
      StatusType.ERROR,
    ];

    if (disabledStatus.includes(record.status)) {
      return <Checkbox disabled />;
    }

    return nodeOriginal;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    renderCell: handleCheckboxRender,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const ShowPaymentModalButton = (props: { recordItemId: number }) => {
    const handleButtonClick = () => {
      setShowId(props.recordItemId);
      setVisiblePaymentShowModal(true);
    };

    return (
      <Button
        size="small"
        type="default"
        icon={<EyeOutlined />}
        onClick={handleButtonClick}
      />
    );
  };

  const { queryResult: paymentQueryResult, setShowId } = useShow<IPayment[]>({
    resource: "payment",
  });

  const { data: showQueryResult, isLoading: showIsLoading } =
    paymentQueryResult;
  const record = showQueryResult?.data;

  const ShowPaymentModal: JSX.Element = (
    <Modal
      visible={visiblePaymentShowModal}
      width={1200}
      onCancel={() => {
        setVisiblePaymentShowModal(false);
      }}
      footer={null}
    >
      <PaymentShow
        record={record ? record[0] : undefined}
        isLoading={showIsLoading}
      />
    </Modal>
  );

  const handleActions = (_text: any, record: IPayment): React.ReactNode => {
    const onlyShowView = [
      StatusType.PAID,
      StatusType.REVERSAL,
      StatusType.PROCESSING,
      StatusType.ERROR,
    ];

    if (onlyShowView.includes(record.status as StatusType)) {
      return (
        <Space>
          <ShowPaymentModalButton recordItemId={record.id} />
        </Space>
      );
    }

    const onDelete = async () => {
      setIsTableLoading(true);

      await deletePayments([record.id]);

      await refetchPaymentTable().then(() => setIsTableLoading(false));
    };

    return (
      <Space>
        <ShowPaymentModalButton recordItemId={record.id} />
        {canDeletePayment && (
          <CustomDeleteButton
            size="small"
            hideText
            body={[record.id]}
            handleDelete={onDelete}
          />
        )}
      </Space>
    );
  };

  const start = async () => {
    setIsTableLoading(true);
  };

  const finish = async () => {
    setIsTableLoading(false);
    setSelectedRowKeys([]);
  };

  const paymentFinish = async () => {
    setSelectedRowKeys([]);
    await refetchPaymentTable();
    setIsTableLoading(false);
  };

  const deleteMany = async () => {
    setIsTableLoading(true);

    const paymentIDs = selectedRowData.map((payment) => payment.id);

    await deletePayments(paymentIDs);

    await refetchPaymentTable().then(() => setIsTableLoading(false));
  };

  const updateMany = async () => {
    setIsTableLoading(true);
    if (accountNumbersToUpdate.length > 0) {
      await updateBalances(accountNumbersToUpdate);
    }
    refetchPaymentTable().then(() => setIsTableLoading(false));
  };

  const PaymentHeaderPage: JSX.Element = (
    <HeaderPage>
      {isAdmin && (
        <Select
          disabled
          options={bankOptions}
          defaultValue={bankId}
          onChange={setBankId}
        />
      )}
      <RefreshButton
        loading={isTableLoading}
        disabled={isTableLoading}
        onClick={updateMany}
      >
        Atualizar
      </RefreshButton>
      <PaymentReportButton
        isTableLoading={isTableLoading}
        filters={filters}
        sorter={sorter}
      />
      {hasSelected && isAllPending && (
        <PaymentSummaryModal
          disabled={isTableLoading}
          data={selectedRowData}
          onStart={start}
          onFinish={paymentFinish}
        />
      )}
      {hasSelected && isPdf && (
        <Voucher
          disabled={isTableLoading}
          data={selectedRowData}
          onDowloadStart={start}
          onDowloadFinish={finish}
        />
      )}
      {hasSelected && canDeleteMany && (
        <CustomDeleteButton
          disabled={isTableLoading}
          body={selectedRowData.map((payment) => payment.id)}
          handleDelete={deleteMany}
        />
      )}
    </HeaderPage>
  );

  const EmptyData: JSX.Element = (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{
        height: 60,
      }}
      description={
        <Typography.Text>Pagamentos não Encontrados</Typography.Text>
      }
    />
  );

  return (
    <TwoSections topElementTitle="Opções" topElement={PaymentHeaderPage}>
      <CustomList
        title={`${batchName === "null" ? "Lote sem nome" : batchName}`}
        headerButtons={ClearButton}
      >
        <Table
          {...tableProps}
          locale={{
            emptyText: EmptyData,
          }}
          rowKey="id"
          key={key}
          rowSelection={rowSelection}
          loading={tableProps.loading || isTableLoading}
          scroll={{ x: 1500 }}
          pagination={{
            ...tableProps.pagination,
            totalBoundaryShowSizeChanger: 10,
          }}
        >
          <Table.Column
            dataIndex={"id"}
            title="ID"
            width={120}
            sorter={true}
            filterDropdown={(props) => (
              <CustomFilterDropdown {...props}>
                <Input placeholder="Filtrar por ID" />
              </CustomFilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"contract"}
            title="Contrato"
            width={140}
            ellipsis={true}
            sorter={true}
            filterDropdown={(props) => (
              <CustomFilterDropdown {...props}>
                <Input placeholder="Filtrar por contrato" />
              </CustomFilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"payerName"}
            title="Nome"
            render={(value: string) => value.toUpperCase()}
            ellipsis={true}
            sorter={true}
            filterDropdown={(props) => (
              <CustomFilterDropdown {...props}>
                <Input placeholder="Filtrar por nome" />
              </CustomFilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"payerFiscalId"}
            title="CPF/CNPJ"
            render={handleFiscalId}
            ellipsis={true}
            width={200}
            sorter={true}
            filterDropdown={(props) => (
              <CustomFilterDropdown {...props}>
                <Input placeholder="Filtrar por CPF/CNPJ" />
              </CustomFilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"status"}
            title="Status"
            render={handleStatus}
            width={150}
            sorter={true}
            filterDropdown={StatusFilter}
          />
          <Table.Column
            dataIndex={"operationType"}
            title="Tipo"
            width={130}
            sorter={true}
            filterDropdown={OperationTypeFilter}
          />
          <Table.Column
            dataIndex={"createdAt"}
            title={<div style={{ whiteSpace: "nowrap" }}>Data criação</div>}
            render={handleDate}
            width={150}
            sorter={true}
            filterDropdown={DateFilter}
          />
          <Table.Column
            title={<div style={{ whiteSpace: "nowrap" }}>Valor devido</div>}
            dataIndex={"dueAmount"}
            render={(value) => (value ? `${convertToBRL(value)}` : "****")}
            ellipsis={true}
            width={120}
          />
          <Table.Column
            dataIndex={["balance", "accountBalance"]}
            title={<div style={{ whiteSpace: "nowrap" }}>Saldo conta</div>}
            render={handleAccountBalance}
            ellipsis={true}
            width={120}
          />
          <Table.Column<IPayment>
            title="Ações"
            dataIndex="actions"
            render={handleActions}
            width={120}
          />
        </Table>
        {ShowPaymentModal}
      </CustomList>
    </TwoSections>
  );
};
