import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "@pankod/refine-antd";
import {
  CrudFilters,
  CrudSorting,
  useCheckError,
  useCustom,
} from "@pankod/refine-core";
import { ReportConfig } from "enums";
import { IPayment } from "interfaces";
import { useState } from "react";
import { buildErrorNotification } from "utils/errorValidation";
import { REPORT_MAX_LINES, ReportGenerator } from "utils/reportGenerator";

interface PaymentReportButtonProps {
  isTableLoading: boolean;
  filters?: CrudFilters;
  sorter?: CrudSorting;
}

export const PaymentReportButton: React.FC<PaymentReportButtonProps> = ({
  isTableLoading,
  filters,
  sorter,
}) => {
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);

  const { mutate: checkError } = useCheckError();

  const getPaymentsForReport = useCustom<IPayment[]>({
    url: "/payment",
    method: "get",
    config: {
      filters,
      sort: sorter,
      query: { _start: 0, _end: REPORT_MAX_LINES },
    },
    queryOptions: { enabled: false },
    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os pagamentos!",
      );
    },
  });

  const report = new ReportGenerator(ReportConfig.PAYMENT);

  const handleGenerateReport = async () => {
    const filterParams = (filters || []).reduce(
      (obj, crudFilter) => {
        if ("field" in crudFilter) {
          obj[crudFilter.field] = crudFilter.value;
        }

        return obj;
      },
      {} as Record<string, any>,
    );

    const getData = async () => {
      const { data: items } = await getPaymentsForReport.refetch();
      const payments = items?.data;

      if (!payments || payments.length === 0) {
        throw new Error("Não foi possível gerar o relatório");
      }

      return payments;
    };

    try {
      report.showModal(getData, filterParams, setIsReportLoading);
    } catch (error) {
      checkError(error);
    }
  };

  return (
    <Button
      icon={<FileExcelOutlined />}
      loading={isReportLoading}
      disabled={isReportLoading || isTableLoading}
      onClick={handleGenerateReport}
    >
      Gerar relatório
    </Button>
  );
};
