/**
 * Converte um valor dado (string ou número) em uma string decimal com duas casas decimais.
 *
 * A função remove todos os caracteres que não são numéricos, garante que haja pelo menos
 * três dígitos e formata o resultado como um número decimal com duas casas decimais.
 * Caso a conversão falhe ou o valor seja inválido, a função retorna '0.00'.
 *
 * @param {string | number} value - O valor de entrada a ser convertido. Pode ser uma string ou um número.
 * @returns {string} - O valor convertido no formato de string decimal com duas casas decimais.
 *
 * @example
 * // Converte um número
 * convertToStringDecimal(12345);
 * // Retorna: '123.45'
 *
 * @example
 * // Converte uma string com caracteres não numéricos
 * convertToStringDecimal('$1,234.56');
 * // Retorna: '1234.56'
 *
 * @example
 * // Converte uma string inválida
 * convertToStringDecimal('abc');
 * // Retorna: '0.00'
 *
 * @example
 * // Converte uma string com menos de 3 dígitos
 * convertToStringDecimal('9');
 * // Retorna: '0.09'
 */
export function convertToStringDecimal(value: string | number): string {
  const parseValue = String(value).replace(/[^0-9]/g, "");

  if (parseValue.length === 0) {
    return "0.00";
  }

  const onlyNumbers = parseValue.padStart(3, "0");

  const decimal = parseFloat(
    onlyNumbers.slice(0, -2) + "." + onlyNumbers.slice(-2),
  );

  return decimal.toFixed(2);
}

export function convertToBRL(value: string | number): string {
  const options: Intl.NumberFormatOptions = {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: 2,
  };
  const currencyFormat = new Intl.NumberFormat("pt-BR", options);
  return currencyFormat.format(Number(value));
}
