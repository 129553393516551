import { Form, Input } from "@pankod/refine-antd";

export const LoginFormItems = (
    <><Form.Item
        name="username"
        label="E-mail do usuário"
        rules={[{ required: true }]}
    >
        <Input
            size="large"
            placeholder="Insira seu e-mail" />
    </Form.Item><Form.Item
        name="password"
        label="Senha"
        rules={[{ required: true }]}
        style={{ marginBottom: "12px" }}
    >
        <Input
            type="password"
            placeholder="●●●●●●●●"
            size="large" />
    </Form.Item></>
);

export const DefinePassFormItems = (
    <><Form.Item
        name="username"
        label="E-mail do usuário"
        rules={[{ required: true }]}
    >
        <Input
            size="large"
            placeholder="Insira seu e-mail" />
    </Form.Item><Form.Item
        name="tempPassword"
        label="Senha Temporária"
        rules={[{ required: true }]}
        style={{ marginBottom: "12px" }}
    >
        <Input.Password size="large" />
    </Form.Item><Form.Item
        name="newPassword"
        label="Nova Senha"
        rules={[
            { required: true },
            () => ({
                validator(_, value) {
                    const hasSpecialChar = /[@#$%^&+=]/.test(value);
                    const hasUppercase = /[A-Z]/.test(value);
                    const hasLowercase = /[a-z]/.test(value);
                    const hasLength = value.length >= 8;

                    if (hasSpecialChar && hasUppercase && hasLowercase && hasLength) {
                        return Promise.resolve();
                    }

                    return Promise.reject('A senha deve ter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula e um caractere especial.');
                },
            }),
        ]}
        style={{ marginBottom: "12px" }}
    >
        <Input.Password size="large" />
    </Form.Item><Form.Item
        name="confirmPassword"
        label="Confirmar Senha"
        dependencies={['newPassword']}
        rules={[
            { required: true },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    const newPassword = getFieldValue('newPassword');
                    if (newPassword === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject('As senhas não coincidem.');
                },
            }),
        ]}
        style={{ marginBottom: "12px" }}
    >
        <Input.Password size="large" />
    </Form.Item></>
);
