import {
  Button,
  Create,
  CreateButton,
  DeleteButton,
  Drawer,
  Edit,
  EditButton,
  EmailField,
  Form,
  Input,
  RefreshButton,
  Select,
  Show,
  ShowButton,
  Space,
  Table,
  Typography,
  notification,
  useDrawerForm,
  useTable,
} from "@pankod/refine-antd";
import { FileExcelOutlined } from "@ant-design/icons";
import {
  CrudFilters,
  HttpError,
  useCheckError,
  useCustom,
  useGetIdentity,
  useNavigation,
  useShow,
} from "@pankod/refine-core";
import { ReportConfig, UserRole } from "enums";
import { SelectOption, IUser, IUserFilterVariables } from "interfaces";
import { useEffect, useState } from "react";

import { REPORT_MAX_LINES, ReportGenerator } from "utils/reportGenerator";

import "dayjs/locale/pt-br";
import { handleBank, handleDate, handleUserRole } from "utils/tableFunctions";
import { UserFilter } from "components/filters";
import { PageTemplate } from "components/template";
import { buildErrorNotification } from "utils/errorValidation";
import { useBank } from "hooks";

const { Title, Text } = Typography;

const allUserOptions: SelectOption[] = [
  {
    label: "Visualizador",
    value: "viewer",
  },
  {
    label: "Aprovador",
    value: "approver",
  },
  {
    label: "Operador",
    value: "operator",
  },
  {
    label: "Gerenciador",
    value: "manager",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

export const UsersList: React.FC = () => {
  const { bankId, setBankId, bankOptions } = useBank();

  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [isRefreshingUsers, setIsRefreshingUsers] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [userOptions, setUserOptions] =
    useState<SelectOption[]>(allUserOptions);

  const { data: user } = useGetIdentity<IUser>();
  const { mutate: checkError } = useCheckError();

  const navigator = useNavigation();

  const report = new ReportGenerator(ReportConfig.USER);

  useEffect(() => {
    if (user) {
      const canAccess = [UserRole.ADMIN, UserRole.MANAGER].includes(
        user.role as UserRole,
      );

      if (!canAccess) {
        navigator.replace("/404");
      }

      setIsAdmin(user.role === UserRole.ADMIN);
      setIsManager(user.role === UserRole.MANAGER);

      if (user.role !== UserRole.ADMIN) {
        const filteredUserOptions = allUserOptions.filter((option) => {
          const isAdmin = option.value === "admin";
          const isManager = option.value === "manager";

          return !isAdmin && !isManager;
        });
        setUserOptions(filteredUserOptions);
      }
    }
  }, [user]);

  useEffect(() => {
    if (bankId) {
      setIsTableLoading(true);
      refetchUserTable().then(() => setIsTableLoading(false));
    }
  }, [bankId]);

  const {
    tableProps,
    sorter,
    filters,
    searchFormProps,
    tableQueryResult: { refetch: refetchUserTable },
  } = useTable<IUser, HttpError, IUserFilterVariables>({
    onSearch: async (params) => {
      const { q, roles } = params;

      let filters: CrudFilters = [];

      filters.push(
        {
          field: "q",
          operator: "contains",
          value: q,
        },
        {
          field: "roles",
          operator: "in",
          value: roles,
        },
      );

      return filters;
    },

    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os usuários!",
      );
    },
  });

  const getUsersForReport = useCustom<IUser[]>({
    url: "users",
    method: "get",
    config: {
      filters,
      sort: sorter,
      query: { _start: 0, _end: REPORT_MAX_LINES },
    },
    queryOptions: {
      enabled: false,
    },
  });

  // Create Drawer
  const {
    formProps: createUserFormProps,
    drawerProps: createUserDrawerProps,
    show: showCreateUser,
    saveButtonProps: createUserButtonProps,
  } = useDrawerForm<IUser>({
    action: "create",
    successNotification: {
      message: `Usuário criado com sucesso`,
      type: "success",
    },
    onMutationSuccess() {
      createUserFormProps.form.resetFields();
    },
  });

  // Edit Drawer
  const {
    formProps: editUserFormProps,
    drawerProps: editUserDrawerProps,
    show: showEditUser,
    saveButtonProps: editUserButtonProps,
    deleteButtonProps: deleteUserButtonProps,
    id,
    formLoading: isEditUserFormLoading,
  } = useDrawerForm<IUser>({
    action: "edit",
    successNotification: {
      message: `Dados do usuário alterados com sucesso`,
      type: "success",
    },
  });

  const [visibleShowDrawer, setVisibleShowDrawer] = useState<boolean>(false);
  const { queryResult, setShowId } = useShow<IUser>();

  const { data: showQueryResult, isLoading: showIsLoading } = queryResult;
  const record = showQueryResult?.data;

  const handleUserRefresh = async () => {
    if (isRefreshingUsers) return;

    setIsRefreshingUsers(true);
    setIsTableLoading(true);

    searchFormProps.form?.submit();
    searchFormProps.form?.resetFields();

    await refetchUserTable();

    setIsTableLoading(false);
    setIsRefreshingUsers(false);
  };

  const handleActions = (_text: any, record: IUser): JSX.Element => {
    const canManagerUsers = isAdmin || isManager;
    const isUserToBeEditedAdmin = record.role === UserRole.ADMIN;
    const canManagerThisUser = isUserToBeEditedAdmin
      ? isAdmin
      : canManagerUsers;

    return (
      <Space>
        <ShowButton
          hideText
          size="small"
          recordItemId={record.email}
          onClick={() => {
            setShowId(record.email);
            setVisibleShowDrawer(true);
          }}
        />
        {canManagerThisUser && (
          <Space>
            <EditButton
              hideText
              size="small"
              recordItemId={record.email}
              onClick={() => showEditUser(record.email)}
            />
            <DeleteButton size="small" recordItemId={record.email} hideText />
          </Space>
        )}
      </Space>
    );
  };

  const ShowDrawer: JSX.Element = (
    <Drawer
      visible={visibleShowDrawer}
      onClose={() => setVisibleShowDrawer(false)}
      width="500"
    >
      <Show
        title={"Ver Usuário"}
        isLoading={showIsLoading}
        headerButtons={<></>}
      >
        <Title level={5}>E-mail</Title>
        <Text>{record?.email}</Text>

        <Title level={5}>Nome</Title>
        <Text>{record?.name}</Text>

        <Title level={5}>Tipo de usuário</Title>
        {record?.role && handleUserRole(record.role)}

        <Title level={5} style={{ marginTop: "20px" }}>
          Bank ID
        </Title>
        <Text>{record?.bankId}</Text>
      </Show>
    </Drawer>
  );

  const EditDrawer: JSX.Element = (
    <Drawer {...editUserDrawerProps}>
      <Edit
        title={"Editar Usuário"}
        recordItemId={id}
        saveButtonProps={editUserButtonProps}
        isLoading={isEditUserFormLoading}
        deleteButtonProps={{
          ...deleteUserButtonProps,
          confirmTitle: "Você tem certeza?",
          confirmOkText: "OK",
          confirmCancelText: "Cancelar",
        }}
        contentProps={{
          style: {
            boxShadow: "none",
          },
          bodyStyle: {
            padding: 0,
          },
        }}
      >
        <Form {...editUserFormProps} layout="vertical">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tipo de usuário"
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={userOptions} />
          </Form.Item>
        </Form>
      </Edit>
    </Drawer>
  );

  const CreateDrawer: JSX.Element = (
    <Drawer {...createUserDrawerProps}>
      <Create
        title={"Criar Usuário"}
        saveButtonProps={createUserButtonProps}
        goBack={false}
        contentProps={{
          style: {
            boxShadow: "none",
          },
          bodyStyle: {
            padding: 0,
          },
        }}
      >
        <Form {...createUserFormProps} layout="vertical">
          <Form.Item
            label="Nome do usuário"
            name="name"
            rules={[
              {
                required: true,
                message: "Digite o nome do usuário",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="E-mail do usuário"
            name="email"
            rules={[
              {
                required: true,
                message: "Digite o e-mail do usuário",
              },
              {
                type: "email",
                message: "E-mail inválido",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tipo de usuário"
            name="role"
            rules={[
              {
                required: true,
                message: "Selecione um tipo de usuário",
              },
            ]}
          >
            <Select options={userOptions} />
          </Form.Item>

          {isAdmin && (
            <Form.Item
              label="Banco do usuário"
              name="bankId"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("role") === "admin" || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Selecione um banco para o usuário"),
                    );
                  },
                }),
              ]}
            >
              <Select options={bankOptions} />
            </Form.Item>
          )}
        </Form>
      </Create>
    </Drawer>
  );

  const headerButtons = () => {
    return (
      <>
        {isAdmin && (
          <Select
            options={bankOptions}
            defaultValue={bankId}
            onChange={setBankId}
          />
        )}
        <RefreshButton
          loading={isTableLoading}
          disabled={isRefreshingUsers || isReportLoading}
          onClick={handleUserRefresh}
        >
          Atualizar
        </RefreshButton>
        <Button
          icon={<FileExcelOutlined />}
          loading={isReportLoading}
          disabled={isRefreshingUsers || isReportLoading}
          onClick={async () => {
            const filterParams = (filters || []).reduce(
              (obj, crudFilter) => {
                if ("field" in crudFilter) {
                  obj[crudFilter.field] = crudFilter.value;
                }

                return obj;
              },
              {} as Record<string, any>,
            );

            const getData = async () => {
              const { data: items } = await getUsersForReport.refetch();
              const users = items?.data;

              if (!users) {
                throw new Error("Não foi possível gerar o relatório");
              }

              return users;
            };

            try {
              report.showModal(getData, filterParams, setIsReportLoading);
            } catch (error) {
              checkError(error);
              const notificationParams = buildErrorNotification(
                error,
                "Não foi possível acessar os usuários!",
              );
              notification.error({
                message: notificationParams.message,
                description: notificationParams.description,
              });
            }
          }}
        >
          Gerar relatório
        </Button>
        {(isAdmin || isManager) && (
          <CreateButton
            onClick={() => {
              showCreateUser();
            }}
            type="primary"
          >
            Adicionar Usuário
          </CreateButton>
        )}
      </>
    );
  };

  const listProps = {
    title: "Usuários",
    headerButtons: headerButtons(),
    canCreate: true,
    createButtonProps: {
      onClick: () => {
        showCreateUser();
        createUserFormProps.form?.resetFields();
      },
    },
  };

  return (
    <PageTemplate
      topElementTitle="Filtro"
      topElement={
        <UserFilter formProps={searchFormProps} userOptions={userOptions} />
      }
      listProps={listProps}
    >
      <Table
        {...tableProps}
        rowKey="email"
        loading={tableProps.loading || isTableLoading}
        pagination={{
          ...tableProps.pagination,
          totalBoundaryShowSizeChanger: 10,
        }}
      >
        <Table.Column
          dataIndex={"email"}
          title="Username / e-mail"
          render={(value: string) => <EmailField value={value} /> ?? "****"}
        />

        <Table.Column
          dataIndex={"name"}
          title="Nome"
          render={(value: string) => value ?? "****"}
        />

        <Table.Column
          dataIndex={"role"}
          title={<div style={{ whiteSpace: "nowrap" }}>Tipo de usuário</div>}
          render={handleUserRole}
        />

        <Table.Column
          dataIndex={"bankId"}
          title="Banco"
          render={(value: string) => handleBank(bankOptions, value)}
        />

        <Table.Column
          dataIndex={"createdAt"}
          title={<div style={{ whiteSpace: "nowrap" }}>Data criação</div>}
          render={handleDate}
        />

        <Table.Column<IUser>
          dataIndex="actions"
          title="Ações"
          render={handleActions}
        />
      </Table>

      {CreateDrawer}
      {EditDrawer}
      {ShowDrawer}
    </PageTemplate>
  );
};
