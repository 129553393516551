export function formatDate(dateString:string) {
    if(!isDate(dateString)) return;

    const date = new Date(dateString);

    let month = String(date.getMonth()+ 1);

    if (month.length === 1) month = `0${month}`;

    let dateOfMonth = String(date.getDate());

    if (dateOfMonth.length === 1) dateOfMonth = `0${dateOfMonth}`;

    const year = date.getFullYear();

    const formattedDate = `${dateOfMonth}/${month}/${year}`;
    return formattedDate;
}

export function formatHour(dateString:string) {

    if(!isDate(dateString)) return;

    const date = new Date(dateString);

    let hour = String(date.getHours());

    if ( hour.length === 1 ) hour = `0${hour}`;

    let minutes = String(date.getMinutes());

    if (minutes.length === 1) minutes = `0${minutes}`;

    const formattedDate = `${hour}h${minutes}`;
    return formattedDate;
}

function isDate(date: string){
    const _regExp  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(date);
}