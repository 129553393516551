import { Row, Alert, Button, Card } from "@pankod/refine-antd";
import { useGetIdentity, useNavigation } from "@pankod/refine-core";
import { CacheModal } from "components/support/cache-modal";
import { CustomList } from "components/template";
import { UserRole } from "enums";
import { IUser } from "interfaces";
import { useEffect, useState } from "react";

export const SupportList: React.FC = () => {
  const { data: user } = useGetIdentity<IUser>();
  const navigator = useNavigation();

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      if (user.role !== UserRole.ADMIN) {
        navigator.replace('/404');
      }
    }
  }, [user]);

  return (
    <CustomList title="Suporte">
      <Row gutter={16}>
        <Card title="Cache" bordered={true}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '16px' }}>
            <Alert
              message="Cuidado!"
              description="Limpar o cache pode deixar as buscas mais lentas"
              type="warning"
              showIcon
            />
            <Button danger type="primary" onClick={() => setShowModal(true)}>Limpar</Button>
            <CacheModal showModal={showModal} setShowModal={setShowModal} />
          </div>
        </Card>
      </Row>
    </CustomList>
  );
}