export function mask(value: string, mask: string): string{
    /**
     * Função para Colocar uma Mascara (CPF / CNPJ / CEP / TELEFONE / etc )
     *
     * @param1 string value - Valor sem formato  EX: 12345678900
     *
     * @param2 string mask  - A Mascara que vai ser aplicada usando a '#'  EX: ###.###.###-##
     *
     * @return string - Retorna o valor inicial com a mascara  EX: 123.456.789-00
     */

    let maskared = '';
    let key = 0;

    for (let index = 0; index < mask.length; index++) {
        
        if(mask[index] ==='#'){
            if(value[key]) maskared = `${maskared}${value[key++]}`;
        }else {
            if(mask[index]) maskared = `${maskared}${mask[index]}`;
        } 
    }

    return maskared;
}

export function fiscalIdMask(fiscalId:string) {

    const onlyNumbers = fiscalId.replace(/[^0-9]/g, '');

    const isCPF = onlyNumbers?.length === 11;

    const isCNPJ = onlyNumbers?.length === 14;

    if(!isCPF && !isCNPJ) return fiscalId;

    if(isCPF) return mask(onlyNumbers,'###.###.###-##');

    return mask(onlyNumbers, '##.###.###/####-##');
}
