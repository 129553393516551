import React, { useState } from "react";
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  G,
  Path,
  Circle,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import {
  IPayment,
  Voucher,
  IPaymentDetailsPixQrcode,
  IPaymentDetailsPix,
  IPaymentDetailsTED,
  IPaymentDetailsIdempotency,
  VoucherBoleto,
  IPaymentDetailsBoleto,
  IPaymentDetailsTEV,
} from "interfaces";
import { formatDate, formatHour } from "utils/dateFormat";
import { fiscalIdMask, mask } from "utils/mask";
import banksInfo from "../../utils/banks.json";
import { Button } from "@pankod/refine-antd";
import { FileSyncOutlined } from "@ant-design/icons";
import { OperationType } from "../../enums";
import { convertToBRL, convertToStringDecimal } from "utils/decimalConverts";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  innerPage: {
    marginTop: 10,
    marginLeft: 35,
    marginRight: 35,
  },

  logo: {
    height: "100",
  },

  image: {
    width: "160",
    height: "75",
  },

  header: {
    fontSize: 15,
    // backgroundColor: "#7FFF00",
    height: "45",
  },
  section: {
    // backgroundColor: "#00FFFF",
  },

  divider: {
    borderTop: "1px dotted #bfbfbf",
    marginTop: 32,
    marginBottom: 15,
  },

  table: {
    display: "flex",
    width: "auto",
  },

  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },

  tableHeader: {
    color: "#808080",
  },

  tableCol: {
    width: "50%",
  },

  tableCell: {
    marginTop: 15,
    fontSize: 13,
    textAlign: "left",
  },
});

type Props = {
  isBatchPageButton?: boolean;
  disabled?: boolean;
  data?: IPayment[] | (() => Promise<IPayment[]>);
  onDowloadStart?: () => Promise<void>;
  onDowloadFinish?: () => Promise<void>;
};

// Create Document Component
const VoucherTemplate: React.FC<Props> = ({
  isBatchPageButton,
  disabled,
  data,
  onDowloadStart,
  onDowloadFinish,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const voucher = (data: Voucher) => {
    const amount = data.amountValue?.replace("-", "") || "0";
    const formattedAmount = convertToStringDecimal(amount);
    const amountMasked = convertToBRL(formattedAmount);

    const categoryPayment = data?.operationType === "TED" ? "TED" : "PIX";

    const receiverName = data?.receiverName?.toUpperCase();
    const receiverBankAgency = data?.receiverBankAgency;
    const receiverBankAccount = data?.receiverBankAccount;
    const receiverBankName = banksInfo.banks.find((bank) => {
      const removeZero = (value: any) => {
        const parse = String(value);
        //  parse.length > 1 -> Necessario, pois o bco do brasil tem ispb_code: 0
        if (parse[0] === "0" && parse.length > 1) return parse.slice(1);
        return parse;
      };
      const bankCode = removeZero(data?.receiverBankCode);

      return bank.ispb_code === bankCode || bank.code === bankCode;
    })?.short_name;

    const receiverFiscalIdRaw =
      data?.receiverFiscalId?.replace(/[^\d]/g, "") || "";
    const isReceiverPF = receiverFiscalIdRaw?.length === 11;

    const receiverFiscalId = mask(
      receiverFiscalIdRaw,
      isReceiverPF ? "###.###.###-##" : "##.###.###/####-##",
    );

    const payerName = data?.payerName?.toUpperCase();
    const payerAccountNumber = data.payerAccountNumber;

    const payerFiscalIdRaw = data?.payerFiscalId?.replace(/[^\d]/g, "") || "";
    const isPayerPF = payerFiscalIdRaw?.length === 11;

    const payerFiscalId = mask(
      payerFiscalIdRaw,
      isPayerPF ? "###.###.###-##" : "##.###.###/####-##",
    );

    const formattedPayer = isPayerPF
      ? `***${payerFiscalId.substring(3, 12)}**`
      : payerFiscalId;

    return (
      <Document>
        <Page style={styles.page} size="A4" key={data.id}>
          <View style={styles.innerPage}>
            <View style={styles.logo}>
              {/* @ts-ignore */}
              <Svg style={{ width: 400, height: 400 }}>
                {/* @ts-ignore */}
                <G>
                  {/* @ts-ignore */}
                  <G>
                    <Path
                      d="m34.40774,56.2223c-10.39127,-0.35262 -18.7365,-8.91116 -18.7365,-19.38949s8.34523,-19.03253 18.7365,-19.38949l0,-14.87079c-18.61896,0.35697 -33.59859,15.55861 -33.59859,34.26029s14.97962,33.90332 33.59859,34.26029l0,-14.87079l0,0l0,-0.00002z"
                      fill="#024EFF"
                    />
                    <Circle
                      cx="49.40043"
                      cy="46.10964"
                      r="7.47022"
                      fill="#FBBA00"
                    />
                    <Circle
                      cx="49.40043"
                      cy="25.75807"
                      r="7.47022"
                      fill="#FBBA00"
                    />
                    <Path
                      d="m100.52531,29.95027c1.21456,1.07961 1.82402,2.70774 1.82402,4.88438l0,8.35394l-3.97454,0l0,-1.82402c-0.79665,1.36258 -2.28547,2.04169 -4.46211,2.04169c-1.1275,0 -2.10263,-0.19154 -2.9254,-0.57028c-0.82712,-0.38309 -1.45399,-0.90548 -1.88932,-1.58024c-0.43533,-0.6704 -0.65299,-1.43223 -0.65299,-2.28547c0,-1.36258 0.51369,-2.42913 1.53671,-3.21272c1.02302,-0.77924 2.60761,-1.17103 4.74942,-1.17103l3.37379,0c0,-0.92725 -0.28296,-1.63683 -0.84454,-2.13746s-1.40611,-0.74876 -2.52925,-0.74876c-0.77924,0 -1.54541,0.12189 -2.29853,0.36568c-0.75312,0.24378 -1.39305,0.57463 -1.91979,0.99255l-1.52365,-2.96458c0.79665,-0.56157 1.75437,-0.9969 2.87316,-1.30598c1.11444,-0.30908 2.2637,-0.46145 3.44344,-0.46145c2.2637,0.00435 4.00501,0.54416 5.21958,1.62377l0,-0.00002zm-3.61757,10.09525c0.56157,-0.3352 0.96207,-0.83148 1.19715,-1.48447l0,-1.49753l-2.91234,0c-1.74131,0 -2.61197,0.57028 -2.61197,1.71519c0,0.54416 0.21331,0.97513 0.63993,1.29292s1.00996,0.47451 1.75437,0.47451c0.727,0.00435 1.37128,-0.16107 1.93285,-0.50063l0.00001,0.00001z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m111.33014,28.87501c0.86195,-0.36132 1.8545,-0.54416 2.98199,-0.54416l0,3.91795c-0.47015,-0.03483 -0.78794,-0.05224 -0.95337,-0.05224c-1.21456,0 -2.16793,0.33956 -2.85575,1.01867s-1.03608,1.70213 -1.03608,3.06035l0,6.913l-4.24444,0l0,-14.64007l4.0529,0l0,1.93285c0.50933,-0.70523 1.1928,-1.24068 2.05475,-1.60636l0,0.00001z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m127.2849,42.48336c-0.41791,0.30908 -0.9316,0.53981 -1.53671,0.69217c-0.60946,0.15236 -1.24939,0.23072 -1.91979,0.23072c-1.74131,0 -3.09083,-0.44403 -4.03984,-1.3321c-0.95337,-0.88807 -1.42787,-2.19405 -1.42787,-3.91795l0,-6.01623l-2.25935,0l0,-3.26496l2.25935,0l0,-3.56533l4.24444,0l0,3.56533l3.64805,0l0,3.26496l-3.64805,0l0,5.95964c0,0.61817 0.15672,1.09267 0.47451,1.42787c0.31779,0.3352 0.76618,0.50498 1.34516,0.50498c0.6704,0 1.24068,-0.18284 1.71519,-0.54416l1.14491,2.99505l0,0.00001z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m148.40264,42.95786c-1.16232,-0.30038 -2.08522,-0.67476 -2.77739,-1.1275l1.41481,-3.04729c0.65299,0.41791 1.44093,0.75747 2.36818,1.01867s1.83273,0.39615 2.7208,0.39615c1.7979,0 2.69468,-0.44403 2.69468,-1.3321c0,-0.41791 -0.24378,-0.71829 -0.7357,-0.89677c-0.48757,-0.18284 -1.24068,-0.3352 -2.25935,-0.46145c-1.19715,-0.18284 -2.18534,-0.39179 -2.96458,-0.62687s-1.45399,-0.65299 -2.02863,-1.25374c-0.57028,-0.60075 -0.8576,-1.44964 -0.8576,-2.55973c0,-0.92725 0.26555,-1.74566 0.801,-2.46395c0.53545,-0.71829 1.31469,-1.27551 2.34206,-1.67166c1.02302,-0.4005 2.23758,-0.60075 3.63499,-0.60075c1.03173,0 2.06345,0.11319 3.08647,0.33956c1.02302,0.22637 1.87191,0.53981 2.54667,0.94031l-1.41481,3.02117c-1.28857,-0.727 -2.69468,-1.08832 -4.21832,-1.08832c-0.90983,0 -1.58895,0.12625 -2.04169,0.38309c-0.45274,0.25249 -0.67911,0.57899 -0.67911,0.97949c0,0.45274 0.24378,0.77053 0.7357,0.95337s1.27116,0.35262 2.34206,0.51804c1.19715,0.20025 2.17664,0.41356 2.93846,0.63993s1.42352,0.63993 1.98509,1.23633c0.56157,0.60075 0.84454,1.43223 0.84454,2.50313c0,0.90548 -0.2699,1.71519 -0.81406,2.42042c-0.54416,0.70958 -1.33646,1.2581 -2.38124,1.64554c-1.04479,0.39179 -2.28112,0.58334 -3.71334,0.58334c-1.21892,0.00435 -2.40736,-0.14801 -3.56969,-0.44839l0,-0.00002z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m83.29504,38.05607c-0.7923,1.11444 -2.08957,1.85014 -3.56098,1.85014c-2.40736,0 -4.36634,-1.95897 -4.36634,-4.36634s1.95897,-4.36634 4.36634,-4.36634c1.47141,0 2.76868,0.73135 3.56098,1.85014l2.97329,-2.0591c-1.44529,-2.05475 -3.83088,-3.40426 -6.53427,-3.40426c-4.40987,0 -7.98391,3.57404 -7.98391,7.98391s3.57404,7.98391 7.98391,7.98391c2.70338,0 5.08898,-1.34952 6.53427,-3.40426l-2.97329,-2.06781l0,0.00001z"
                      fill="#024EFF"
                    />
                    <Circle
                      cx="136.10464"
                      cy="36.05357"
                      r="7.47021"
                      fill="#FBBA00"
                    />
                  </G>
                </G>
              </Svg>
            </View>

            <View style={styles.header}>
              <Text
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 25,
                }}
              >
                Comprovante de Transferência
              </Text>
              <Text style={{ fontSize: 12, marginBottom: 12 }}>
                {payerName}
              </Text>
              <Text style={{ color: "#8B8B8B", fontSize: 10 }}>
                {payerAccountNumber}
              </Text>
            </View>

            <View style={styles.divider}></View>

            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Valor(R$)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {amountMasked} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Tipo da transação
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {categoryPayment} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Data da transferência
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {" "}
                      {formatDate(data?.operationDate || "")}{" "}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Horário
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {formatHour(data?.operationDate || "")}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Código da transação
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {data?.id} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Descrição
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {data?.description} </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.divider}></View>

            <View style={styles.section}>
              <Text style={{ fontWeight: "bold" }}>Conta de Origem</Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Instituição
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>BANCO CARTOS SCD S.A</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Agência
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> 0001 </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Conta
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {payerAccountNumber} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      CPF/CNPJ
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {formattedPayer} </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.divider}></View>

            <View style={styles.section}>
              <Text style={{ fontWeight: "bold" }}>Conta de Destino</Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Instituição
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {receiverBankName} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Agência
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {receiverBankAgency} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Conta
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {" "}
                      {receiverBankAccount}{" "}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      CPF/CNPJ
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {receiverFiscalId} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Nome
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {receiverName} </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const voucherBoleto = (data: VoucherBoleto) => {
    const amountMasked = convertToBRL(data.amountValue);
    const fee = convertToBRL(data.fee);
    const discount = convertToBRL(data.discount);
    const fine = convertToBRL(data.fine);
    const totalValue = convertToBRL(data.totalValue);

    const receiverName = data?.receiverName?.toUpperCase();
    const receiverBankName = data.receiverBankName.toUpperCase();

    const receiverFiscalIdRaw =
      data?.receiverFiscalId?.replace(/[^\d]/g, "") || "";
    const receiverFiscalId = fiscalIdMask(receiverFiscalIdRaw);

    const payerName = data?.payerName?.toUpperCase();
    const payerAccountNumber = data.payerAccountNumber;

    const payerFiscalIdRaw = data?.payerFiscalId?.replace(/[^\d]/g, "") || "";
    const payerFiscalId = fiscalIdMask(payerFiscalIdRaw);

    return (
      <Document>
        <Page style={styles.page} size="A4" key={data.id}>
          <View style={styles.innerPage}>
            <View style={styles.logo}>
              {/* @ts-ignore */}
              <Svg style={{ width: 400, height: 400 }}>
                {/* @ts-ignore */}
                <G>
                  {/* @ts-ignore */}
                  <G>
                    <Path
                      d="m34.40774,56.2223c-10.39127,-0.35262 -18.7365,-8.91116 -18.7365,-19.38949s8.34523,-19.03253 18.7365,-19.38949l0,-14.87079c-18.61896,0.35697 -33.59859,15.55861 -33.59859,34.26029s14.97962,33.90332 33.59859,34.26029l0,-14.87079l0,0l0,-0.00002z"
                      fill="#024EFF"
                    />
                    <Circle
                      cx="49.40043"
                      cy="46.10964"
                      r="7.47022"
                      fill="#FBBA00"
                    />
                    <Circle
                      cx="49.40043"
                      cy="25.75807"
                      r="7.47022"
                      fill="#FBBA00"
                    />
                    <Path
                      d="m100.52531,29.95027c1.21456,1.07961 1.82402,2.70774 1.82402,4.88438l0,8.35394l-3.97454,0l0,-1.82402c-0.79665,1.36258 -2.28547,2.04169 -4.46211,2.04169c-1.1275,0 -2.10263,-0.19154 -2.9254,-0.57028c-0.82712,-0.38309 -1.45399,-0.90548 -1.88932,-1.58024c-0.43533,-0.6704 -0.65299,-1.43223 -0.65299,-2.28547c0,-1.36258 0.51369,-2.42913 1.53671,-3.21272c1.02302,-0.77924 2.60761,-1.17103 4.74942,-1.17103l3.37379,0c0,-0.92725 -0.28296,-1.63683 -0.84454,-2.13746s-1.40611,-0.74876 -2.52925,-0.74876c-0.77924,0 -1.54541,0.12189 -2.29853,0.36568c-0.75312,0.24378 -1.39305,0.57463 -1.91979,0.99255l-1.52365,-2.96458c0.79665,-0.56157 1.75437,-0.9969 2.87316,-1.30598c1.11444,-0.30908 2.2637,-0.46145 3.44344,-0.46145c2.2637,0.00435 4.00501,0.54416 5.21958,1.62377l0,-0.00002zm-3.61757,10.09525c0.56157,-0.3352 0.96207,-0.83148 1.19715,-1.48447l0,-1.49753l-2.91234,0c-1.74131,0 -2.61197,0.57028 -2.61197,1.71519c0,0.54416 0.21331,0.97513 0.63993,1.29292s1.00996,0.47451 1.75437,0.47451c0.727,0.00435 1.37128,-0.16107 1.93285,-0.50063l0.00001,0.00001z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m111.33014,28.87501c0.86195,-0.36132 1.8545,-0.54416 2.98199,-0.54416l0,3.91795c-0.47015,-0.03483 -0.78794,-0.05224 -0.95337,-0.05224c-1.21456,0 -2.16793,0.33956 -2.85575,1.01867s-1.03608,1.70213 -1.03608,3.06035l0,6.913l-4.24444,0l0,-14.64007l4.0529,0l0,1.93285c0.50933,-0.70523 1.1928,-1.24068 2.05475,-1.60636l0,0.00001z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m127.2849,42.48336c-0.41791,0.30908 -0.9316,0.53981 -1.53671,0.69217c-0.60946,0.15236 -1.24939,0.23072 -1.91979,0.23072c-1.74131,0 -3.09083,-0.44403 -4.03984,-1.3321c-0.95337,-0.88807 -1.42787,-2.19405 -1.42787,-3.91795l0,-6.01623l-2.25935,0l0,-3.26496l2.25935,0l0,-3.56533l4.24444,0l0,3.56533l3.64805,0l0,3.26496l-3.64805,0l0,5.95964c0,0.61817 0.15672,1.09267 0.47451,1.42787c0.31779,0.3352 0.76618,0.50498 1.34516,0.50498c0.6704,0 1.24068,-0.18284 1.71519,-0.54416l1.14491,2.99505l0,0.00001z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m148.40264,42.95786c-1.16232,-0.30038 -2.08522,-0.67476 -2.77739,-1.1275l1.41481,-3.04729c0.65299,0.41791 1.44093,0.75747 2.36818,1.01867s1.83273,0.39615 2.7208,0.39615c1.7979,0 2.69468,-0.44403 2.69468,-1.3321c0,-0.41791 -0.24378,-0.71829 -0.7357,-0.89677c-0.48757,-0.18284 -1.24068,-0.3352 -2.25935,-0.46145c-1.19715,-0.18284 -2.18534,-0.39179 -2.96458,-0.62687s-1.45399,-0.65299 -2.02863,-1.25374c-0.57028,-0.60075 -0.8576,-1.44964 -0.8576,-2.55973c0,-0.92725 0.26555,-1.74566 0.801,-2.46395c0.53545,-0.71829 1.31469,-1.27551 2.34206,-1.67166c1.02302,-0.4005 2.23758,-0.60075 3.63499,-0.60075c1.03173,0 2.06345,0.11319 3.08647,0.33956c1.02302,0.22637 1.87191,0.53981 2.54667,0.94031l-1.41481,3.02117c-1.28857,-0.727 -2.69468,-1.08832 -4.21832,-1.08832c-0.90983,0 -1.58895,0.12625 -2.04169,0.38309c-0.45274,0.25249 -0.67911,0.57899 -0.67911,0.97949c0,0.45274 0.24378,0.77053 0.7357,0.95337s1.27116,0.35262 2.34206,0.51804c1.19715,0.20025 2.17664,0.41356 2.93846,0.63993s1.42352,0.63993 1.98509,1.23633c0.56157,0.60075 0.84454,1.43223 0.84454,2.50313c0,0.90548 -0.2699,1.71519 -0.81406,2.42042c-0.54416,0.70958 -1.33646,1.2581 -2.38124,1.64554c-1.04479,0.39179 -2.28112,0.58334 -3.71334,0.58334c-1.21892,0.00435 -2.40736,-0.14801 -3.56969,-0.44839l0,-0.00002z"
                      fill="#024EFF"
                    />
                    <Path
                      d="m83.29504,38.05607c-0.7923,1.11444 -2.08957,1.85014 -3.56098,1.85014c-2.40736,0 -4.36634,-1.95897 -4.36634,-4.36634s1.95897,-4.36634 4.36634,-4.36634c1.47141,0 2.76868,0.73135 3.56098,1.85014l2.97329,-2.0591c-1.44529,-2.05475 -3.83088,-3.40426 -6.53427,-3.40426c-4.40987,0 -7.98391,3.57404 -7.98391,7.98391s3.57404,7.98391 7.98391,7.98391c2.70338,0 5.08898,-1.34952 6.53427,-3.40426l-2.97329,-2.06781l0,0.00001z"
                      fill="#024EFF"
                    />
                    <Circle
                      cx="136.10464"
                      cy="36.05357"
                      r="7.47021"
                      fill="#FBBA00"
                    />
                  </G>
                </G>
              </Svg>
            </View>

            <View style={styles.header}>
              <Text
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 25,
                }}
              >
                Comprovante de Pagamento
              </Text>
              <Text style={{ fontSize: 12, marginBottom: 12 }}>
                {payerName}
              </Text>
              <Text style={{ color: "#8B8B8B", fontSize: 10 }}>
                {payerAccountNumber}
              </Text>
            </View>

            <View style={styles.divider}></View>

            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Valor(R$)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {amountMasked} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Valor Total(R$)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {totalValue} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Juros(R$)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {fee} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Desconto(R$)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {discount} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Multa(R$)
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {fine} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Tipo da transação
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> Boleto de cobrança </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Data da transferência
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {" "}
                      {formatDate(data?.operationDate || "")}{" "}
                    </Text>
                  </View>
                </View>

                {/* <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={[styles.tableCell,styles.tableHeader]}>Data do Vencimento</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}> {formatDate(data?.operationDate || '')} </Text> 
                                    </View>
                                </View> */}

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Horário
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {formatHour(data?.operationDate || "")}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Código da transação
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {data?.id} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Linha digitável
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell} wrap>
                      {data?.receiverBoletoNumber}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.divider}></View>

            <View style={styles.section}>
              <Text style={{ fontWeight: "bold" }}>Quem pagou</Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Instituição
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>BANCO CARTOS SCD S.A</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      CPF/CNPJ
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {payerFiscalId} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Nome
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {payerName} </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.divider}></View>

            <View style={styles.section}>
              <Text style={{ fontWeight: "bold" }}>Quem recebeu</Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Instituição
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {receiverBankName} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      CPF/CNPJ
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {receiverFiscalId} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={[styles.tableCell, styles.tableHeader]}>
                      Nome
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> {receiverName} </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const handleMultiInterfaces = (data: any) => {
    switch (true) {
      case "value" in data && data?.value?.category === "PIX": // PIX
        const { value: pix } = data as IPaymentDetailsPix;
        return voucher({
          id: pix.transactionData.operationNumber || pix.transactionId,
          operationType: "PIX",
          operationDate: pix.createdAt,
          amountValue: pix.amount,
          description: pix.transactionData.description,
          payerFiscalId: pix.account.documentNumber,
          payerName: pix.account.nameOwner,
          payerAccountNumber: pix.account.number,
          receiverFiscalId: pix.transactionData.recept_cpf_cnpj,
          receiverName: pix.transactionData.clientFantasyName,
          receiverBankCode: pix.transactionData.bank,
          receiverBankAccount: pix.transactionData.account,
          receiverBankAgency: pix.transactionData.agency,
        });

      case data?.trasactionData?.type === "qr_code_static": // PIXQRCODE
        const pixQrcode = data as IPaymentDetailsPixQrcode;
        return voucher({
          id:
            pixQrcode.transactionData.operationNumber ||
            pixQrcode.transactionId,
          operationType: pixQrcode.category,
          operationDate: pixQrcode.createdAt,
          amountValue: pixQrcode.amount,
          description: "",
          payerFiscalId: pixQrcode.account.documentNumber,
          payerName: pixQrcode.account.nameOwner,
          payerAccountNumber: pixQrcode.account.number,
          receiverFiscalId: String(
            pixQrcode.transactionData?.cpf || pixQrcode.transactionData?.cnpj,
          ),
          receiverName: pixQrcode.transactionData.clientName,
          receiverBankCode: pixQrcode.transactionData.bank,
          receiverBankAccount: pixQrcode.transactionData.account,
          receiverBankAgency: pixQrcode.transactionData.agency,
        });

      case data?.category === "TEV": // TEV (PIX ou TED cartos p/ cartos)
        const paymentTev = data as IPaymentDetailsTEV;

        const isPJ = paymentTev.transactionData.clientType === "PJ";
        return voucher({
          id: paymentTev.transactionId,
          operationType: paymentTev.category,
          operationDate: paymentTev.createdAt,
          amountValue: paymentTev.amount,
          description: paymentTev.transactionData.history,
          payerFiscalId: paymentTev.account.documentNumber,
          payerName: paymentTev.account.nameOwner,
          payerAccountNumber: paymentTev.account.number,
          receiverFiscalId: paymentTev.transactionData.clientDocument,
          receiverName: isPJ
            ? paymentTev.transactionData.clientCompanyName
            : paymentTev.transactionData.clientName,
          receiverBankCode: paymentTev.transactionData.ispbDestinyBank,
          receiverBankAccount: paymentTev.transactionData.accountNumber,
          receiverBankAgency: paymentTev.transactionData.agency,
        });
      case data?.category === "TED": // TED
        const payment = data as IPaymentDetailsTED;
        return voucher({
          id: payment.transactionId,
          operationType: payment.category,
          operationDate: payment.createdAt,
          amountValue: payment.amount,
          description: payment.transactionData.history,
          payerFiscalId: payment.account.documentNumber,
          payerName: payment.account.nameOwner,
          payerAccountNumber: payment.account.number,
          receiverFiscalId: String(
            payment.transactionData?.cpf || payment.transactionData?.cnpj,
          ),
          receiverName: payment.transactionData.clientName,
          receiverBankCode: payment.transactionData.bankISPB,
          receiverBankAccount: payment.transactionData.account,
          receiverBankAgency: payment.transactionData.agency,
        });

      case "dataValues" in data: // Idempotency
        const { dataValues: paymentIdempotency } =
          data as IPaymentDetailsIdempotency;
        return voucher({
          id:
            paymentIdempotency.transactionData.operationNumber ||
            paymentIdempotency.transactionId,
          operationType: paymentIdempotency.category,
          operationDate: paymentIdempotency.createdAt,
          amountValue: paymentIdempotency.amount,
          description: "",
          payerFiscalId: paymentIdempotency.account.documentNumber,
          payerName: paymentIdempotency.account.nameOwner,
          payerAccountNumber: paymentIdempotency.account.number,
          receiverFiscalId: String(
            paymentIdempotency.transactionData?.cpf ||
              paymentIdempotency.transactionData?.cnpj,
          ),
          receiverName: paymentIdempotency.transactionData.clientName,
          receiverBankCode: paymentIdempotency.transactionData.bank,
          receiverBankAccount: paymentIdempotency.transactionData.account,
          receiverBankAgency: paymentIdempotency.transactionData.agency,
        });

      case data?.operationType === "boleto": // BOLETO
        const paymentData = data as IPayment;
        let boletoData = paymentData.paymentDetails as IPaymentDetailsBoleto;
        if ("dataValues" in boletoData) {
          const transactionData = (boletoData as any).dataValues
            .transactionData;
          boletoData = transactionData as IPaymentDetailsBoleto;
        }
        return voucherBoleto({
          id: boletoData.controlNumber,
          operationType: OperationType.BOLETO,
          operationDate: paymentData.transactionDate || "",
          amountValue: boletoData.paidValue,
          payerFiscalId: boletoData.payerAccount.documentNumber,
          payerName: boletoData.payerAccount.name,
          payerAccountNumber: paymentData.payerAccountNumber,
          receiverFiscalId: boletoData.receiverInformations.documentNumber,
          receiverName: boletoData.receiverInformations.name,
          receiverBankName: boletoData.senderInstitution,
          receiverBoletoNumber: paymentData.receiverBoletoNumber || "",
          duedate: "",
          fee: boletoData.payerAccount.fees || 0,
          discount: boletoData.payerAccount.discount || 0,
          fine: boletoData.payerAccount.fine || 0,
          totalValue: boletoData.totalValue || 0,
        });
      default:
        break;
    }
  };

  const generateVoucher = (data: IPayment) => {
    if (!data.paymentDetails) return;

    const isBoleto = data.operationType === "boleto";

    const voucherData = isBoleto ? data : data.paymentDetails;

    return handleMultiInterfaces(voucherData);
  };

  const handleGeneratePDFs = async () => {
    let payments: IPayment[] | undefined;

    if (typeof data === "function") {
      payments = await data();
    } else {
      payments = data;
    }

    setIsLoading(true);
    if (onDowloadStart) onDowloadStart();

    const zip = new JSZip();

    await Promise.all(
      payments?.map(async (item: IPayment, index: number) => {
        const pdfVoucher = generateVoucher(item);

        if (!pdfVoucher) return;

        const pdfBlob = await pdf(pdfVoucher).toBlob();

        const formattedValue =
          Number(item?.dueAmount)?.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          }) || "";

        const filename = `${index + 1} - ${item.payerName.toUpperCase()} ${formattedValue}.pdf`;

        zip.file(filename, pdfBlob);
      }) ?? [],
    );

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "comprovantes_pagamento.zip");
    setIsLoading(false);
    if (onDowloadFinish) onDowloadFinish();
  };

  return (
    <>
      <Button
        type={isBatchPageButton ? "text" : undefined}
        icon={<FileSyncOutlined />}
        onClick={handleGeneratePDFs}
        disabled={isLoading || disabled}
        loading={isLoading}
      >
        {isLoading ? "Gerando..." : "Gerar comprovantes"}
      </Button>
    </>
  );
};

export default VoucherTemplate;
