import {
  Show,
  Typography,
  Tag,
  Col,
  Row,
  Divider,
  Space,
  Button,
} from "@pankod/refine-antd";
import { CopyOutlined } from "@ant-design/icons";
import { OperationType } from "enums";
import { IPayment } from "interfaces";
import { useEffect, useState } from "react";
import { formatDate } from "utils/dateFormat";
import { fiscalIdMask } from "utils/mask";
import { convertToBRL } from "utils/decimalConverts";

const { Title, Text } = Typography;

export const PaymentShow = (props: {
  record: IPayment | undefined;
  isLoading: boolean;
}) => {
  const [status, setStatus] = useState<{ color: string; name: string }>({
    color: "",
    name: "",
  });
  const [hasError, setHasError] = useState<boolean>(false);

  const record = props.record;

  useEffect(() => {
    const statusMap: { [key: string]: { color: string; name: string } } = {
      paid: { color: "green", name: "Pago" },
      pending: { color: "yellow", name: "Pendente" },
      processing: { color: "blue", name: "Processando" },
      unavailable: { color: "gray", name: "Indisponível" },
      reversal: { color: "purple", name: "Estornado" },
      error: { color: "red", name: "Erro" },
      default: { color: "", name: "" },
    };

    const inputStatus = record?.status || "default";

    const newStatus = statusMap[inputStatus];

    setStatus(newStatus);
    setHasError(newStatus.name === "Erro");
  }, [record?.status]);

  const receiverFiscalIdMasked = fiscalIdMask(record?.receiverFiscalId ?? "");

  const payerpaymentFiscalIdMasked = fiscalIdMask(record?.payerFiscalId ?? "");

  const errorList = record?.errorMessage?.split("---");

  const errorListRender = errorList?.map((error, index) => (
    <Col style={{ marginBottom: 25 }} lg={24} md={24} sm={24} xs={24}>
      <Title level={5}>{index + 1}º Tentativa</Title>
      <Text>{error}</Text>
    </Col>
  ));

  const renderReceiverData = (operationType?: OperationType) => {
    const bankData = (
      <Row>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Nome</Title>
          <Text>{record?.receiverName?.toUpperCase()}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Código do banco</Title>
          <Text>{record?.receiverBankCode}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>CPF/CNPJ</Title>
          <Text>{receiverFiscalIdMasked}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Agência</Title>
          <Text>{record?.receiverAgency}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Conta</Title>
          <Text>{record?.receiverAccount}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Descrição</Title>
          <Text>{record?.paymentDescription}</Text>
        </Col>
      </Row>
    );

    const qrcodePixData = (
      <Row>
        <Col style={{ marginBottom: 25 }} lg={12} md={12} sm={12} xs={24}>
          <Title level={5}>Nome</Title>
          <Text>{record?.receiverName?.toUpperCase()}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={12} md={12} sm={12} xs={24}>
          <Title level={5}>CPF/CNPJ</Title>
          <Text>{receiverFiscalIdMasked}</Text>
        </Col>
        <Col style={{ marginBottom: 25 }} lg={24} md={24} sm={24} xs={24}>
          <Title level={5}>
            <Space style={{ marginRight: 10 }}>Código PIX</Space>
            <Space>
              <Button
                onClick={() =>
                  navigator.clipboard.writeText(record?.receiverPix ?? "")
                }
                size="small"
              >
                <CopyOutlined />
              </Button>
            </Space>
          </Title>

          <Text>{record?.receiverPix?.substring(0, 35) + "..."}</Text>
        </Col>
      </Row>
    );

    const boletoData = (
      <Row>
        <Col style={{ marginBottom: 25 }} lg={12} md={12} sm={12} xs={24}>
          <Title level={5}>Nome</Title>
          <Text>{record?.receiverName?.toUpperCase()}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={12} md={12} sm={12} xs={24}>
          <Title level={5}>CPF/CNPJ</Title>
          <Text>{receiverFiscalIdMasked}</Text>
        </Col>
        <Col style={{ marginBottom: 25 }} lg={24} md={24} sm={24} xs={24}>
          <Title level={5}> Linha digitavel </Title>

          <Text>{record?.receiverBoletoNumber}</Text>
        </Col>
      </Row>
    );

    const emptyData = (
      <Row>
        <Text>Algo deu erro: dados de pagamento nao encontrado !!</Text>
      </Row>
    );

    switch (operationType?.toLowerCase()) {
      case OperationType.TED:
      case OperationType.PIX:
        return bankData;

      case OperationType.QRCODEPIX:
        return qrcodePixData;

      case OperationType.BOLETO:
        return boletoData;

      default:
        return emptyData;
    }
  };

  return (
    <Show
      title="Detalhes do Pagamento"
      isLoading={props.isLoading}
      canDelete={false}
      headerButtons={<></>}
    >
      <Row>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={3}>
            Status <Tag color={status.color}>{status.name}</Tag>
          </Title>
        </Col>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>
            Data de criação: {formatDate(String(record?.createdAt))}
          </Title>
        </Col>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>
            Lote: {record?.batchName?.toUpperCase() || "(vazio)"}
          </Title>
        </Col>
      </Row>
      <Row>{hasError ? errorListRender : null}</Row>
      <Divider orientation="center">
        <Title level={4}>Dados do pagador (quem paga)</Title>
      </Divider>
      <Row>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Contrato</Title>
          <Text>{record?.contract}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Nome</Title>
          <Text>{record?.payerName?.toUpperCase()}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>CPF/CNPJ</Title>
          <Text>{payerpaymentFiscalIdMasked}</Text>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Código do banco</Title>
          <Text>{record?.payerBankCode ?? "(vazio)"}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Agência</Title>
          <Text>{record?.payerAgency ?? "(vazio)"}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Conta</Title>
          <Text>{record?.payerAccountNumber ?? "(vazio)"}</Text>
        </Col>
      </Row>

      <Divider orientation="center">
        <Title level={4}>Dados de quitação (quem recebe)</Title>
      </Divider>

      {renderReceiverData(record?.operationType)}

      <Divider orientation="center">
        <Title level={4}>Dados de operação</Title>
      </Divider>

      <Row>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Vencimento</Title>
          <Text>{formatDate(String(record?.dueDate))}</Text>
        </Col>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Salvo devedor</Title>
          <Text>{convertToBRL(record?.dueAmount || "")}</Text>
        </Col>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Valor Conta digital</Title>
          <Text>{convertToBRL(record?.digitalAccountValue || "")}</Text>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Custo de abertura</Title>
          <Text>{convertToBRL(record?.openingCost || "")}</Text>
        </Col>

        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Custo da operação</Title>
          <Text>{convertToBRL(record?.transactionCost || "")}</Text>
        </Col>
        <Col style={{ marginBottom: 25 }} lg={8} md={8} sm={24} xs={24}>
          <Title level={5}>Tipo da operação</Title>
          <Text>{record?.operationType?.toUpperCase()}</Text>
        </Col>
      </Row>
    </Show>
  );
};
