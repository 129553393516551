import {
  Table,
  Input,
  TableProps,
  Button,
  Empty,
  Typography,
} from "@pankod/refine-antd";
import { FilterOutlined } from "@ant-design/icons";
import { TwoSections, CustomList } from "components/template";
import { IBatch } from "interfaces";
import { convertToBRL } from "utils/decimalConverts";
import { handleBatchStatus, handleDate } from "utils/tableFunctions";

import { BaseKey, CrudFilters, CrudSorting } from "@pankod/refine-core";
import { useCallback, useEffect, useState } from "react";
import {
  DateFilter,
  OperationTypeFilter,
  StatusFilter,
} from "components/filters";
import { BatchPageType } from "enums";
import { CustomFilterDropdown } from "components/customFilterDropDown";

interface BatchesTemplatePageProps {
  topElement: React.ReactNode;
  title: string;
  pageType: BatchPageType;
  tableProps: TableProps<IBatch>;
  setFilters: ((filters: CrudFilters, behavior?: "merge" | "replace") => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
  setSorter: (sorter: CrudSorting) => void;
  isTableLoading: boolean;
  handleActions: (_text: any, record: IBatch) => React.ReactNode;
  showImportPayments?: (id?: BaseKey) => void;
}

export const BatchesTemplatePage: React.FC<BatchesTemplatePageProps> = ({
  topElement,
  title,
  pageType,
  tableProps,
  setFilters,
  setSorter,
  isTableLoading,
  handleActions,
  showImportPayments,
}) => {
  const [key, setKey] = useState(0);
  const [showStatusFilter, setShowStatusFilter] = useState<boolean>(true);
  const [showDateFilter, setShowDateFilter] = useState<boolean>(true);
  const [isCleaning, setIsCleaning] = useState<boolean>(false);

  useEffect(() => {
    switch (pageType) {
      case BatchPageType.APPROVE:
        setShowStatusFilter(false);
        setShowDateFilter(true);
        break;
      case BatchPageType.IMPORT:
        setShowStatusFilter(true);
        setShowDateFilter(false);
        break;
      default:
        setShowStatusFilter(true);
        setShowDateFilter(true);
        break;
    }
  }, [pageType]);

  const clearFilters = useCallback(() => {
    setIsCleaning(true);
    setFilters([], "replace");
    setSorter([
      {
        field: "createdAt",
        order: "desc",
      },
    ]);
    setKey((prevKey) => prevKey + 1);
    setIsCleaning(false);
  }, [setSorter]);

  const ClearButton: JSX.Element = (
    <Button
      disabled={isTableLoading}
      loading={isCleaning}
      icon={<FilterOutlined />}
      onClick={clearFilters}
    >
      Limpar filtros
    </Button>
  );

  const EmptyData: JSX.Element = (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{
        height: 60,
      }}
      description={
        <Typography.Text>
          {showImportPayments
            ? "Não há lotes nos últimos 3 dias"
            : "Lotes não encontrados"}
        </Typography.Text>
      }
    >
      {showImportPayments && (
        <Button
          type="primary"
          onClick={() => {
            showImportPayments();
          }}
        >
          Importar Lote
        </Button>
      )}
    </Empty>
  );

  return (
    <TwoSections topElementTitle="Opções" topElement={topElement}>
      <CustomList title={title} headerButtons={ClearButton}>
        <Table
          {...tableProps}
          locale={{
            emptyText: EmptyData,
          }}
          key={key}
          rowKey="batchName"
          loading={tableProps.loading || isTableLoading}
          scroll={{ x: 1000 }}
          pagination={{
            ...tableProps.pagination,
            totalBoundaryShowSizeChanger: 10,
          }}
        >
          <Table.Column
            dataIndex={"batchName"}
            title="Lote"
            render={(value) => value ?? "(vazio)"}
            ellipsis={true}
            width={200}
            sorter={true}
            filterDropdown={(props) => (
              <CustomFilterDropdown {...props}>
                <Input placeholder="Filtrar por Lote" />
              </CustomFilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"status"}
            title="Status"
            render={handleBatchStatus}
            width={160}
            filterDropdown={showStatusFilter ? StatusFilter : null}
          />
          <Table.Column
            dataIndex={"operationType"}
            title="Tipo"
            render={(value: string[]) => value.join(" | ")}
            sorter={true}
            filterDropdown={OperationTypeFilter}
          />
          <Table.Column
            dataIndex={"totalPayments"}
            title="Total de pgto."
            ellipsis={true}
          />
          <Table.Column
            dataIndex={"createdAt"}
            title={<div style={{ whiteSpace: "nowrap" }}>Data de envio</div>}
            render={handleDate}
            sorter={showDateFilter}
            filterDropdown={showDateFilter ? DateFilter : null}
            width={160}
          />
          <Table.Column
            title={<div style={{ whiteSpace: "nowrap" }}>Total devido</div>}
            dataIndex={"totalDueAmount"}
            render={(value) => (value ? `${convertToBRL(value)}` : "****")}
            ellipsis={true}
          />
          <Table.Column
            dataIndex={"totalAccountBalance"}
            title={<div style={{ whiteSpace: "nowrap" }}>Total de saldo</div>}
            render={(value) => (value ? `${convertToBRL(value)}` : "****")}
            ellipsis={true}
          />
          <Table.Column<IBatch>
            title="Ações"
            dataIndex="actions"
            render={handleActions}
            width={100}
          />
        </Table>
      </CustomList>
    </TwoSections>
  );
};
