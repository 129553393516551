import { useGetIdentity, useIsExistAuthentication } from "@pankod/refine-core";
import { axiosInstance } from "authProvider";
import { Bank, IUser, SelectOption } from "interfaces";
import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

interface IBankState {
  bankId: string;
  setBankId: React.Dispatch<string>;
  bankOptions: SelectOption[];
}

export const BankStateContext = createContext<IBankState>(null!);

export function BankStateProvider({ children }: { children: ReactNode }) {
  const { data: user } = useGetIdentity<IUser>();
  const [bankOptions, setBankOptions] = useState<SelectOption[]>([]);

  const isAuthenticated = useIsExistAuthentication();

  const [bankId, setBankId] = useReducer((_: string, newBankId: string) => {
    const headers = axiosInstance.defaults.headers.common;

    if (newBankId) {
      headers["bankId"] = newBankId;
    } else {
      delete headers["bankId"];
    }

    return newBankId;
  }, user?.bankId ?? "");

  useEffect(() => {
    if (user?.bankId) {
      setBankId(user.bankId);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated) {
      axiosInstance
        .get<Bank[]>("/bank")
        .then((response) => {
          const banks = response.data;
          if (!banks) return;

          const options: SelectOption[] = banks.map((bank) => ({
            label: `${bank.externalBankId} - ${bank.name}`,
            value: bank.externalBankId,
          }));

          setBankOptions(options);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (bankId && bankOptions.length > 0) {
      const validOption = bankOptions.find((option) => option.value === bankId);
      if (!validOption && bankOptions.length > 0) {
        setBankId("");
      }
    }
  }, [bankOptions, bankId]);

  const value = useMemo(
    () => ({
      bankId,
      setBankId,
      bankOptions,
    }),
    [bankId, bankOptions],
  );

  return (
    <BankStateContext.Provider value={value}>
      {children}
    </BankStateContext.Provider>
  );
}
