import { Row, Col } from 'antd';
import React, { ReactNode } from 'react';

interface HeaderPageProps {
  children: ReactNode[];
}

export const HeaderPage: React.FC<HeaderPageProps> = ({ children }) => {

  return (
    <Row gutter={[8, 8]} justify="start" wrap={true}>
      {children.map((child, index) => {
        if (!child) {
          return null;
        }
        const key = `col-${index}`;
        return (
          <Col
            key={key}
            flex={'none'}
          >
            {child}
          </Col>
        );
      })}
    </Row>
  );
};
