import { RefreshButton } from "@pankod/refine-antd";
import { usePayment } from "hooks";
import { IBatch } from "interfaces";
import { useState, useEffect } from "react";

interface UpdateBatchHeaderButtonProps {
  batches: readonly IBatch[];
  isTableLoading: boolean;
  setIsTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetchBatchTable: any;
}

export const UpdateBatchHeaderButton: React.FC<
  UpdateBatchHeaderButtonProps
> = ({ batches, isTableLoading, setIsTableLoading, refetchBatchTable }) => {
  const [accountNumbersToUpdate, setAccountNumbersToUpdate] = useState<
    string[]
  >([]);

  const { updateBalances } = usePayment();

  useEffect(() => {
    if (batches.length > 0) {
      const accountNumbers = Array.from(
        new Set(batches.flatMap((batch) => batch.accountNumbersToUpdate)),
      );
      setAccountNumbersToUpdate(accountNumbers);
    }
  }, [batches]);

  const updateMany = async () => {
    setIsTableLoading(true);
    if (accountNumbersToUpdate.length > 0) {
      await updateBalances(accountNumbersToUpdate);
    }
    refetchBatchTable().then(() => setIsTableLoading(false));
  };

  return (
    <RefreshButton
      disabled={isTableLoading}
      loading={isTableLoading}
      onClick={updateMany}
    >
      Atualizar
    </RefreshButton>
  );
};
