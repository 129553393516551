import { Modal, notification } from "@pankod/refine-antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import {
  IPayment,
  IPaymentFilterVariables,
  IUser,
  IUserFilterVariables,
} from "interfaces";
import { OperationType, ReportConfig } from "enums";
import { fiscalIdMask } from "./mask";
import dayjs from "dayjs";
import { buildErrorNotification } from "./errorValidation";

export const REPORT_MAX_LINES = 5000;

export class ReportGenerator {
  private readonly config: ReportConfig;

  constructor(config: ReportConfig) {
    this.config = config;
  }

  showModal(
    getData: () => Promise<IPayment[] | IUser[]>,
    params: IPaymentFilterVariables | IUserFilterVariables,
    setIsReportLoading: (value: React.SetStateAction<boolean>) => void,
  ): void {
    const { confirm } = Modal;

    const modalText = `O seu relatório será um arquivo CSV gerado a partir da tabela, levando em consideração os filtros selecionados. Além disso, conterá até ${REPORT_MAX_LINES} linhas.`;

    const onOk = async () => {
      setIsReportLoading(true);

      try {
        const data = await getData();

        if (data.length === 0) {
          throw new Error("Não há dados correspondentes aos filtros");
        }

        switch (this.config) {
          case ReportConfig.PAYMENT:
            const paymentFileName = this.buildPaymentReportName(
              "relatorio_pagamentos",
              params as IPaymentFilterVariables,
            );
            await this.createPaymentReportFile(
              data as IPayment[],
              paymentFileName,
            );
            break;
          case ReportConfig.USER:
            const userFileName = this.buildUserReportName(
              "relatorio_usuarios",
              params as IUserFilterVariables,
            );
            await this.createUserReportFile(data as IUser[], userFileName);
            break;
          default:
            break;
        }
      } catch (error) {
        const notificationParams = buildErrorNotification(
          error,
          "Não foi possível gerar o relatório!",
        );
        notification.error({
          message: notificationParams.message,
          description: notificationParams.description,
        });
      } finally {
        setIsReportLoading(false);
      }
    };

    confirm({
      icon: <ExclamationCircleOutlined style={{ color: "#67BE23" }} />,
      title: "Gerar Relatório",
      content: <p>{modalText}</p>,
      onOk: onOk,
    });
  }

  private buildPaymentReportName(
    baseName: string,
    params: IPaymentFilterVariables,
  ): string {
    const { status, batchName } = params;

    let reportName = baseName;

    if (batchName) {
      reportName += `-lote-${batchName}`;
    }

    if (status) {
      if (typeof status === "string") {
        reportName += `-status-${status}`;
      } else {
        reportName += `-status-${status.join("_")}`;
      }
    }

    const now = dayjs();

    const formattedDateTime = now.format("YYYY-MM-DDTHH:mm:ssZ[Z]");

    reportName += formattedDateTime;

    return reportName.replace(/\s/g, "");
  }

  private buildUserReportName(
    baseName: string,
    params: IUserFilterVariables,
  ): string {
    const { q, roles } = params;

    let reportName = baseName;
    if (q) reportName += `_${q}`;
    if (roles) reportName += `-roles-${roles.join("_")}`;

    return reportName;
  }

  async createPaymentReportFile(
    data: IPayment[],
    fileName: string,
  ): Promise<void> {
    if (Array.isArray(data) && data.length > 0) {
      try {
        const wb = XLSX.utils.book_new();

        const bankDataURL = `${process.env.REACT_APP_API_URL}/payment/bankdata/template?auth=sdwe%403qwqja!we`;
        const boletoURL = `${process.env.REACT_APP_API_URL}/payment/boleto/template?auth=sdwe%403qwqja!we`;
        const pixQRCodeURL = `${process.env.REACT_APP_API_URL}/payment/qrcode/template?auth=sdwe%403qwqja!we`;

        const generalReport = this.buildGeneralPaymentReport(data);

        const reportWS = XLSX.utils.json_to_sheet(generalReport);
        const bankDataWS = await this.getWSFromAPI(bankDataURL);
        const boletosWS = await this.getWSFromAPI(boletoURL);
        const pixQRCodeWS = await this.getWSFromAPI(pixQRCodeURL);

        const configDataType = {
          generalReport: { monetaryCols: [11, 20, 22, 23, 25] },
          bankData: { monetaryCols: [12, 15, 16, 17] },
          boleto: { monetaryCols: [9] },
          pixQRCode: { monetaryCols: [9, 11, 12, 13] },
        };

        if (!bankDataWS || !boletosWS || !pixQRCodeWS) {
          return;
        }

        const { bankData, boletos, pixQRCode } =
          this.classificatePaybills(data);

        const monetaryFormat = '#,##0.00_);-* #,##0.00;_-* "-"??_-;_-@_-'; //123,456.78

        this.formatColumns({
          worksheet: reportWS,
          cols: configDataType.generalReport.monetaryCols,
          dataType: "n",
          numformatString: monetaryFormat,
        });

        XLSX.utils.book_append_sheet(wb, reportWS, "Relatorio");

        if (bankData.length > 0) {
          XLSX.utils.sheet_add_json(bankDataWS, bankData, {
            origin: 2,
            skipHeader: true,
          });

          this.formatColumns({
            worksheet: bankDataWS,
            cols: configDataType.bankData.monetaryCols,
            dataType: "n",
            numformatString: monetaryFormat,
            skip: 2,
          });

          XLSX.utils.book_append_sheet(wb, bankDataWS, "TED e PIX");
        }

        if (boletos.length > 0) {
          XLSX.utils.sheet_add_json(boletosWS, boletos, {
            origin: 2,
            skipHeader: true,
          });

          this.formatColumns({
            worksheet: boletosWS,
            cols: configDataType.boleto.monetaryCols,
            dataType: "n",
            numformatString: monetaryFormat,
            skip: 2,
          });

          XLSX.utils.book_append_sheet(wb, boletosWS, "Boletos");
        }

        if (pixQRCode.length > 0) {
          XLSX.utils.sheet_add_json(pixQRCodeWS, pixQRCode, {
            origin: 2,
            skipHeader: true,
          });

          this.formatColumns({
            worksheet: pixQRCodeWS,
            cols: configDataType.pixQRCode.monetaryCols,
            dataType: "n",
            numformatString: monetaryFormat,
            skip: 2,
          });

          XLSX.utils.book_append_sheet(wb, pixQRCodeWS, "PIX QR Code");
        }

        XLSX.writeFile(wb, `${fileName}.xlsx`, {
          bookType: "xlsx",
          type: "buffer",
        });
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error("Dados para relatório foram obtidos de forma errada");
    }
  }

  private buildGeneralPaymentReport(data: IPayment[]) {
    const reportData = data.map((payment: IPayment) => {
      return {
        id: payment.id,
        Lote: payment.batchName ?? "",
        Status: this.handlePaymentStatus(payment.status),
        "Mensagem de erro": payment.errorMessage ?? "",
        "Tipo de operacao": payment.operationType,
        Contrato: payment.contract,
        "Nome do pagador": payment.payerName,
        "CPF/CNPJ do pagador": fiscalIdMask(payment.payerFiscalId),
        "Banco do pagador": payment.payerBankCode,
        "Agencia do pagador": payment.payerAgency,
        "Conta do pagador": payment.payerAccountNumber,
        "Saldo da conta do pagador": payment.payerAccountBalance,
        "Nome do recebedor": payment.receiverName,
        "CPF/CNPJ do recebedor": fiscalIdMask(payment.receiverFiscalId),
        "Banco do recebedor": payment.receiverBankCode ?? "",
        "Agencia do recebedor": payment.receiverAgency ?? "",
        "Conta do recebedor": payment.receiverAccount ?? "",
        "Tipo de conta do recebedor": payment.receiverAccountType ?? "",
        "QR Code Pix": payment.receiverPix ?? "",
        "Numero do boleto do recebedor": payment.receiverBoletoNumber ?? "",
        "Valor saldo devedor": payment.dueAmount,
        "Data de vencimento": payment.dueDate
          ? new Date(payment.dueDate)
          : null,
        "Custo de abertura": payment.openingCost ?? null,
        "Custo operacao": payment.transactionCost ?? null,
        "Data de transacao": payment.transactionDate
          ? new Date(payment.transactionDate)
          : null,
        "Deposito conta digital": payment.digitalAccountValue,
        "Descricao de pagamento": payment.paymentDescription ?? "",
        "Criado em": new Date(payment.createdAt),
        "Atualizado em": new Date(payment.updatedAt),
      };
    });

    return reportData;
  }

  private handlePaymentStatus(value: string): string {
    const mapStatusToInfo: { [key: string]: { name: string } } = {
      pending: { name: "Pendente" },
      paid: { name: "Pago" },
      reversal: { name: "Estornado" },
      error: { name: "Erro" },
      unavailable: { name: "Indisponivel" },
      processing: { name: "Processando" },
    };

    const defaultStatusInfo = { name: value };
    const statusInfo = mapStatusToInfo[value] || defaultStatusInfo;

    return statusInfo.name;
  }

  private async getWSFromAPI(
    url: string,
    sheetRef: number | string = 0,
  ): Promise<XLSX.WorkSheet | undefined> {
    try {
      const response = await fetch(url);
      const data = await response.arrayBuffer();
      const wb = XLSX.read(new Uint8Array(data), { type: "array" });

      let ws: XLSX.WorkSheet;

      if (typeof sheetRef === "number") {
        ws = wb.Sheets[wb.SheetNames[sheetRef]];
      } else {
        ws = wb.Sheets[sheetRef];
      }

      return ws;
    } catch (error) {
      throw error;
    }
  }

  private classificatePaybills(data: IPayment[]): {
    bankData: object[];
    boletos: object[];
    pixQRCode: object[];
  } {
    const bankData: object[] = [];
    const boletos: object[] = [];
    const pixQRCode: object[] = [];

    data.forEach((payment) => {
      if (!payment.operationType) {
        return;
      }

      switch (payment.operationType as OperationType) {
        case OperationType.PIX:
          const reportPIX = this.buildReportPIXTED(payment);
          bankData.push(reportPIX);
          break;
        case OperationType.TED:
          const reportTED = this.buildReportPIXTED(payment);
          bankData.push(reportTED);
          break;
        case OperationType.BOLETO:
          const reportBoleto = this.buildReportBoleto(payment);
          boletos.push(reportBoleto);
          break;
        case OperationType.QRCODEPIX:
          const reportPixQRCode = this.buildReportPixQRCode(payment);
          pixQRCode.push(reportPixQRCode);
          break;
        default:
          break;
      }
    });

    return { bankData, boletos, pixQRCode };
  }

  private buildReportPIXTED(payment: IPayment) {
    const reportPIXTED = {
      //Dados Clientes ( Quem paga )
      contract: payment.contract,
      payerName: payment.payerName,
      payerFiscalId: fiscalIdMask(payment.payerFiscalId),
      //Conta Cartos
      payerBankCode: payment.payerBankCode,
      payerAgency: payment.payerAgency,
      payerAccountNumber: payment.payerAccountNumber,
      //Dados Quitação ( Quem recebe )
      receiverName: payment.receiverName,
      receiverFiscalId: fiscalIdMask(payment.receiverFiscalId),
      receiverBankCode: payment.receiverBankCode ?? "",
      receiverAgency: payment.receiverAgency ?? "",
      receiverAccount: payment.receiverAccount ?? "",
      receiverAccountType: payment.receiverAccountType ?? "",
      dueAmount: payment.dueAmount,
      paymentDescription: payment.paymentDescription ?? "",
      dueDate: payment.dueDate ? new Date(payment.dueDate) : null,
      //Custos Operação
      openingCost: payment.openingCost ?? "",
      transactionCost: payment.transactionCost ?? "",
      digitalAccountValue: payment.digitalAccountValue,
      operationType: payment.operationType,
      //Lote
      batchName: payment.batchName ?? "",
    };
    return reportPIXTED;
  }

  private buildReportBoleto(payment: IPayment) {
    const reportBoleto = {
      //Dados Clientes ( Quem paga )
      contract: payment.contract,
      payerName: payment.payerName,
      payerFiscalId: fiscalIdMask(payment.payerFiscalId),
      //Conta Cartos
      payerBankCode: payment.payerBankCode,
      payerAgency: payment.payerAgency,
      payerAccountNumber: payment.payerAccountNumber,
      //Dados Quitação ( Quem recebe )
      receiverName: payment.receiverName,
      receiverFiscalId: fiscalIdMask(payment.receiverFiscalId),
      receiverBoletoNumber: payment.receiverBoletoNumber ?? "",
      dueAmount: payment.dueAmount,
      //Lote
      batchName: payment.batchName ?? "",
    };
    return reportBoleto;
  }

  private buildReportPixQRCode(payment: IPayment) {
    const reportPixQRCode = {
      //Dados Clientes ( Quem paga )
      contract: payment.contract,
      payerName: payment.payerName,
      payerFiscalId: fiscalIdMask(payment.payerFiscalId),
      //Conta Cartos
      payerBankCode: payment.payerBankCode,
      payerAgency: payment.payerAgency,
      payerAccountNumber: payment.payerAccountNumber,
      //Dados Quitação ( Quem recebe )
      receiverName: payment.receiverName,
      receiverFiscalId: fiscalIdMask(payment.receiverFiscalId),
      receiverPix: payment.receiverPix ?? "",
      dueAmount: payment.dueAmount,
      dueDate: payment.dueDate ? new Date(payment.dueDate) : null,
      //Custos Operação
      openingCost: payment.openingCost ?? null,
      transactionCost: payment.transactionCost ?? null,
      digitalAccountValue: payment.digitalAccountValue,
      //Lote
      batchName: payment.batchName ?? "",
    };

    return reportPixQRCode;
  }

  private formatColumns({
    worksheet,
    cols,
    dataType = "s",
    numformatString,
    skip = 1,
  }: {
    worksheet: XLSX.WorkSheet;
    cols: number[];
    dataType: XLSX.ExcelDataType;
    numformatString?: string;
    skip?: number;
  }): void {
    const refString = worksheet["!ref"];
    if (typeof refString === "string") {
      const range = XLSX.utils.decode_range(refString);
      const startingCellNum = range.s.r;
      const endingCellNum = range.e.r;

      for (let col of cols) {
        for (let row = startingCellNum + skip; row <= endingCellNum; ++row) {
          const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
          if (worksheet[cellRef]) {
            worksheet[cellRef].t = dataType;
            switch (dataType) {
              //n means number
              case "n":
                //Number format string associated with the cell
                if (numformatString) worksheet[cellRef].z = numformatString;
                break;
              default:
                break;
            }
          }
        }
      }
    }
  }

  private createUserReportFile(data: IUser[], fileName: string) {
    const reportData = data.map((user: IUser) => {
      return {
        Nome: user.name,
        "E-mail": user.email,
        Tipo: this.handleUserRole(user.role),
        Banco: user.bankId,
        "Criado em": user.createdAt ? new Date(user.createdAt) : null,
        "Atualizado em": user.updatedAt ? new Date(user.updatedAt) : null,
      };
    });

    if (Array.isArray(data) && data.length > 0) {
      const wb = XLSX.utils.book_new();

      const ws = XLSX.utils.json_to_sheet(reportData);

      XLSX.utils.book_append_sheet(wb, ws, "Relatorio");

      XLSX.writeFile(wb, `${fileName}.xlsx`, {
        bookType: "xlsx",
        type: "buffer",
      });
    } else {
      throw new Error("Dados para relatório foram obtidos de forma errada");
    }

    return reportData;
  }

  private handleUserRole(value: string): string {
    const mapRoleToInfo: { [key: string]: { name: string } } = {
      admin: { name: "Admin" },
      manager: { name: "Gerenciador" },
      viewer: { name: "Visualizador" },
      operator: { name: "Operador" },
      approver: { name: "Aprovador" },
    };

    const defaultRoleInfo = { name: value };
    const statusInfo = mapRoleToInfo[value] || defaultRoleInfo;

    return statusInfo.name;
  }
}

