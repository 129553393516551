import { Modal, Typography, notification, Button } from "@pankod/refine-antd";
import { useApiUrl, useCheckError } from "@pankod/refine-core";
import { axiosInstance } from "authProvider";
import { useState } from "react";
import { buildErrorNotification } from "utils/errorValidation";

interface CacheModalProps {
  showModal: boolean,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const CacheModal: React.FC<CacheModalProps> = ({ showModal, setShowModal }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const apiUrl = useApiUrl();
  const { mutate: checkError } = useCheckError();

  const okButtonAction = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.post(`${apiUrl}/admin-tools/clear-cache`);

      if (response.status === 201) {
        notification.success({ message: "Limpeza do cache concluída!" });
      }
    } catch (error) {
      checkError(error);
      const notificationParams = buildErrorNotification(
        error,
        "Não foi possível limpar o cache!",
      );
      notification.error({
        message: notificationParams.message,
        description: notificationParams.description,
        duration: null
      });
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  }

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={[
        <Button
          danger
          type={"primary"}
          onClick={okButtonAction}
          loading={loading}
        >
          Limpar
        </Button>,
        <Button
          onClick={() => setShowModal(false)}
        >
          Cancelar
        </Button>
      ]}
    >
      <div>
        <Typography.Title type="danger" level={4}>
          Ação Crítica: Limpeza de Cache
        </Typography.Title>
        <Typography.Text>
          Limpar o cache pode ajudar a resolver alguns problemas, mas pode tornar a aplicação mais lenta temporariamente. Deseja continuar?
        </Typography.Text>
      </div>
    </Modal>
  );
}