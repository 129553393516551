import { useTable, Select } from "@pankod/refine-antd";
import {
  CrudFilters,
  CrudSorting,
  HttpError,
  useGetIdentity,
  useNavigation,
} from "@pankod/refine-core";
import { IBatch, IUser } from "interfaces";
import { useState, useEffect, useMemo } from "react";

import "dayjs/locale/pt-br";

import { BatchPageType, UserRole } from "enums";
import { buildErrorNotification } from "utils/errorValidation";
import { HeaderPage } from "components/header";
import { BatchActions } from "components/batch/actions";
import { BatchesTemplatePage } from "components/batch/template";
import { UpdateBatchHeaderButton } from "components/buttons/updateBatchHeader";
import { PaymentReportButton } from "components/buttons/paymentReport";
import { useBank } from "hooks";

export const ApproveBatchesList: React.FC = () => {
  const { bankId, setBankId, bankOptions } = useBank();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

  const { data: user } = useGetIdentity<IUser>();

  const navigator = useNavigation();

  useEffect(() => {
    if (user) {
      const userRole = user.role as UserRole;

      const canAccess = [
        UserRole.ADMIN,
        UserRole.MANAGER,
        UserRole.APPROVER,
      ].includes(user.role as UserRole);

      if (!canAccess) {
        navigator.replace("/404");
      }

      setIsAdmin(userRole === UserRole.ADMIN);
    }
  }, [user]);

  useEffect(() => {
    if (bankId) {
      setIsTableLoading(true);
      refetchBatchTable().then(() => setIsTableLoading(false));
    }
  }, [bankId]);

  const permanentFilter: CrudFilters = useMemo(
    () => [
      {
        field: "status",
        operator: "in",
        value: ["pending"],
      },
    ],
    [],
  );

  const initialSorter: CrudSorting = useMemo(
    () => [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    [],
  );

  const {
    tableProps,
    sorter,
    setSorter,
    filters,
    setFilters,
    tableQueryResult: { refetch: refetchBatchTable },
  } = useTable<IBatch, HttpError>({
    resource: "batch",
    permanentFilter: permanentFilter,
    initialSorter: initialSorter,
    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os lotes!",
      );
    },
  });

  const handleActions = (_text: any, record: IBatch): React.ReactNode => {
    return (
      <BatchActions
        batch={record}
        isApprovePage
        refetchTable={async () => {
          await refetchBatchTable();
        }}
        isLoading={isTableLoading}
        setIsLoading={setIsTableLoading}
      />
    );
  };

  const BatchHeaderPage: JSX.Element = (
    <HeaderPage>
      {isAdmin && (
        <Select
          style={{ minWidth: "100px" }}
          disabled={isTableLoading}
          options={bankOptions}
          defaultValue={bankId}
          onChange={setBankId}
        />
      )}
      <UpdateBatchHeaderButton
        batches={tableProps.dataSource ?? []}
        isTableLoading={isTableLoading}
        setIsTableLoading={setIsTableLoading}
        refetchBatchTable={refetchBatchTable}
      />
      <PaymentReportButton
        isTableLoading={isTableLoading}
        filters={filters}
        sorter={sorter}
      />
    </HeaderPage>
  );

  return (
    <div>
      <BatchesTemplatePage
        topElement={BatchHeaderPage}
        title="Aprovar lotes"
        pageType={BatchPageType.APPROVE}
        tableProps={tableProps}
        isTableLoading={isTableLoading}
        handleActions={handleActions}
        setFilters={setFilters}
        setSorter={setSorter}
      />
    </div>
  );
};
