import React from "react";
import { Card, Col, List, Row } from "@pankod/refine-antd";
import { ActionButtonRenderer } from '@pankod/refine-ui-types';

interface TwoSectionsProps {
  topElementTitle?: string;
  topElement: React.ReactNode;
  children?: React.ReactNode;
}

interface PageTemplateProps<THeaderProps extends {} = Record<keyof any, unknown>> {
  topElementTitle?: string;
  topElement: React.ReactNode;
  listProps: Omit<CustomListProps<THeaderProps>, 'children'>;
  children?: React.ReactNode;
}

interface CustomListProps<
  THeaderProps extends {} = Record<keyof any, unknown>,
  TCreateButtonProps extends {} = Record<keyof any, unknown>,
> {
  title: string;
  headerButtons?: ActionButtonRenderer;
  headerProps?: THeaderProps;
  createButtonProps?: TCreateButtonProps;
  children?: React.ReactNode;
}

class TwoSections extends React.Component<TwoSectionsProps> {
  render() {
    const {
      topElementTitle,
      topElement,
      children,
    } = this.props;

    return (
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <Card title={topElementTitle}>
            {topElement}
          </Card>
        </Col>
        {children}
      </Row>
    );
  }
}

class PageTemplate extends React.Component<PageTemplateProps> {

  render() {
    const {
      topElementTitle,
      topElement,
      listProps,
      children,
    } = this.props;

    return (
      <TwoSections
        topElementTitle={topElementTitle}
        topElement={topElement}
      >
        <CustomList
          title={listProps.title}
          headerButtons={listProps.headerButtons}
          headerProps={listProps.headerProps}
        >
          {children}
        </CustomList>
      </TwoSections>
    );
  }
}

class CustomList extends React.Component<CustomListProps> {

  render() {

    const {
      title,
      headerButtons,
      headerProps,
      createButtonProps,
      children,
    } = this.props;

    return (
      <Col lg={24} xs={24}>
        <List
          title={title}
          headerButtons={headerButtons}
          headerProps={headerProps}
          createButtonProps={createButtonProps}
        >
          {children}
        </List>
      </Col>
    );
  }
}

export { TwoSections, PageTemplate, CustomList };